import { GET_DEBTOR_SUMMARY_REPORT, GET_CONTACTS_RTO, GET_SALES_MANAGER_REPORT, GET_PORJECTED_COLLECTION_REPORT, SET_OVERVIEW_OF_SALES_OF_DEBTOR, GET_BUSY_TOTAL_SALE } from "../constants";

export const setDebtorSummaryReport = (debtorSummaryReport) => ({
    type: GET_DEBTOR_SUMMARY_REPORT,
    payload: debtorSummaryReport
});

export const setBusyTotalSale = (busyTotalSale) => ({
    type: GET_BUSY_TOTAL_SALE,
    payload: busyTotalSale
});

export const setContactsRTO = (contactsRTO) => ({
    type: GET_CONTACTS_RTO,
    payload: contactsRTO
});

export const setSalesManagerReport = (salesManagerReport) => ({
    type: GET_SALES_MANAGER_REPORT,
    payload: salesManagerReport
});
export const setProjectedCollectionReport = (projectedCollectionReport) => ({
    type: GET_PORJECTED_COLLECTION_REPORT,
    payload: projectedCollectionReport
});

export const setOverviewOfSalesOfDebtor = (overview) => ({
    type: SET_OVERVIEW_OF_SALES_OF_DEBTOR,
    payload: overview
});

import {
    GET_CONTACT_RECEIPTS, GET_CONTACT_PENDING_PAID_BILLS, GET_CONTACT_CREDIT_NOTES, GET_CONTACT_DEBIT_NOTES, GET_CONTACT_INVOICES,
    GET_RECEIVABLES, GET_ADVANCEDPAID, GET_BRANCHES, GET_DUE_AGEING_SUMMARY_CONTACTWISE, GET_LEDGER_PDF_DATA_BY_POUCH, GET_REMINDER_PREFILL_MAIL, SET_COMMUNICATION_HISTORY_FROM_LOKI,
    GET_REMINDER_PREFILL_SMS, GET_REMINDER_PREFILL_WHATSAPP, SET_BULK_CREDFLOW_DETAILS, GET_BULK_DOWNLOAD_INVOICE, GET_TAG_VOUCHER_DATA, SET_INVOICE_FOR_FINANCE, SET_INVOICE_DATA_FOR_INVOICE_FINANCE, GET_REMINDER_MAIL_SUBJECT,
    GET_SINGLE_DOWNLOAD_INVOICE, SET_PRIORITY_FLAG,GET_LEDGER_HTML_DATA, SET_KYC_ORG_ID, SET_APPLICATION_STATE, SET_NEW_BANNER, SET_CRM_STAGES_DATA_FOR_CUSTOMER, SET_CRM_NOTES, SET_LOS_KYC_STATUS, SET_LOS_GST,
    SET_SKIP_MANDATE, SET_BUSINESS_TYPE, SET_LOS_APPLICATION_ID, SET_LOS_ELIGIBLE_BANK, SET_LOS_DESIRED_LIMIT, SET_MANDATE_NACH_STATUS, SET_USER_JOURNEY_COMPLETED, SET_ALL_ORGANIZATION_USERS, SET_LOS_REFRESH
} from "../constants";

export const setContactReceipts = (contactReceipts) => ({
    type: GET_CONTACT_RECEIPTS,
    payload: contactReceipts
});

export const setContactInvoices = (contactInvoices) => ({
    type: GET_CONTACT_INVOICES,
    payload: contactInvoices
});

export const setContactCreditNotes = (contactCreditNotes) => ({
    type: GET_CONTACT_CREDIT_NOTES,
    payload: contactCreditNotes
});

export const setContactDebitNotes = (contactDebitNotes) => ({
    type: GET_CONTACT_DEBIT_NOTES,
    payload: contactDebitNotes
});


export const setContactPendingAndPaidBills = (contactPendingAndPaidBills) => ({
    type: GET_CONTACT_PENDING_PAID_BILLS,
    payload: contactPendingAndPaidBills
});
export const setDueAgeingSummaryContactwise = (dueAgeingSummaryContactwiseData) => ({
    type: GET_DUE_AGEING_SUMMARY_CONTACTWISE,
    payload: dueAgeingSummaryContactwiseData
})
export const setReceivablesResponse = (payload) => ({
    type: GET_RECEIVABLES,
    payload: payload
});

export const setLedgerPdfDataByPouch = (payload) => ({
    type: GET_LEDGER_PDF_DATA_BY_POUCH,
    payload: payload
});

export const setLedgerHTMLData = (payload) => ({
    type: GET_LEDGER_HTML_DATA,
    payload: payload
});

export const setAdvancePaidResponse = (payload) => ({
    type: GET_ADVANCEDPAID,
    payload: payload
});

export const setBranchesResponse = (payload) => ({
    type: GET_BRANCHES,
    payload: payload
});

export const setReminderPrefillForMail = (payload) => ({
    type: GET_REMINDER_PREFILL_MAIL,
    payload: payload
});

export const setReminderMailSubject = (payload) => ({
    type: GET_REMINDER_MAIL_SUBJECT,
    payload: payload
});

export const setReminderPrefillForSms = (payload) => ({
    type: GET_REMINDER_PREFILL_SMS,
    payload: payload
});

export const setReminderPrefillForWhatsapp = (payload) => ({
    type: GET_REMINDER_PREFILL_WHATSAPP,
    payload: payload
});

export const setMultipleInvoiceDownload = (getBulkInvoiceDownload) => ({
    type: GET_BULK_DOWNLOAD_INVOICE,
    payload: getBulkInvoiceDownload
});
export const setBulkCredflowDetails = (bulkCredflowDetails) => ({
    type: SET_BULK_CREDFLOW_DETAILS,
    payload: bulkCredflowDetails
});
export const setTagVoucherData = (tagVoucherData) => ({
    type: GET_TAG_VOUCHER_DATA,
    payload: tagVoucherData
});
export const setInvoiceForFinance = (payload) => ({
    type: SET_INVOICE_FOR_FINANCE,
    payload: payload
});
export const setInvoiceDataForInvFinance = (payload) => ({
    type: SET_INVOICE_DATA_FOR_INVOICE_FINANCE,
    payload: payload
});
export const setCommunicationHistoryFromLoki = (payload) => ({
    type: SET_COMMUNICATION_HISTORY_FROM_LOKI,
    payload: payload
});

export const setSingleInvoiceDownload = (getSingleInvoiceDownload) => ({
    type: GET_SINGLE_DOWNLOAD_INVOICE,
    payload: getSingleInvoiceDownload
});
export const setPriorityFlag = (payload) => ({
    type: SET_PRIORITY_FLAG,
    payload: payload
});
export const setKycOrgId = (payload) => ({
    type: SET_KYC_ORG_ID,
    payload: payload
});
export const setKycApplicationState = (payload) => ({
    type: SET_APPLICATION_STATE,
    payload: payload
});
export const setShowNewBanner = (payload) => ({
    type: SET_NEW_BANNER,
    payload: payload
});
export const setLosGstNumber = (payload) => ({
    type: SET_LOS_GST,
    payload: payload
});
export const setLosKycStatus = (payload) => ({
    type: SET_LOS_KYC_STATUS,
    payload: payload
});
export const setCrmStageDataForCustomer = (payload) => ({
    type: SET_CRM_STAGES_DATA_FOR_CUSTOMER,
    payload: payload
});
export const setCrmNotesOfUser = (payload) => ({
    type: SET_CRM_NOTES,
    payload: payload
});
export const setSkipMandate = (payload) => ({
    type: SET_SKIP_MANDATE,
    payload: payload
});
export const setBusinessType = (payload) => ({
    type: SET_BUSINESS_TYPE,
    payload: payload
});
export const setLosApplicationId = (payload) => ({
    type: SET_LOS_APPLICATION_ID,
    payload: payload
})
export const setLosEligibleBank = (payload) => ({
    type: SET_LOS_ELIGIBLE_BANK,
    payload: payload
})
export const setLosDesiredLimit = (payload) => ({
    type: SET_LOS_DESIRED_LIMIT,
    payload: payload
})
export const setMandateNachStatus = (payload) => ({
    type: SET_MANDATE_NACH_STATUS,
    payload: payload
})
export const setUserJourneyCompleted = (payload) => ({
    type: SET_USER_JOURNEY_COMPLETED,
    payload: payload
})

export const setAllOrganizationUsers = (payload) => ({
    type: SET_ALL_ORGANIZATION_USERS,
    payload: payload
})

export const setLosRefresh = (payload) => ({
    type: SET_LOS_REFRESH,
    payload: payload
});
export const UPDATE_AUTH = 'UPDATE_AUTH';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const UPDATE_CUSTOMERS = 'UPDATE_CUSTOMERS';
export const UPDATE_DUE_AGEING_SUMMARY_FOR_CONTACT = 'UPDATE_DUE_AGEING_SUMMARY_FOR_CONTACT';
export const SET_ALL_USERS = "SET_ALL_USERS"

// actions used on the receipts screen
export const UPDATE_RECEIPT_CUSTOMERS = 'UPDATE_RECEIPT_CUSTOMERS';
export const UPDATE_RECEIPTS = 'UPDATE_RECEIPTS';
export const UPDATE_VOUCHERS = 'UPDATE_VOUCHERS';
export const APPROVE_RECEIPT = 'APPROVE_RECEIPT';
export const DISAPPROVE_RECEIPT = 'DISAPPROVE_RECEIPT';
export const SET_ACCOUNTS = "SET_ACCOUNTS"
export const SET_ALL_RECEIPTS = 'SET_ALL_RECEIPTS';
export const SET_RECEIPT_PREFILL_CONTENT = 'SET_RECEIPT_PREFILL_CONTENT';
export const SET_VOUCHERS_BY_STATUS = 'SET_VOUCHERS_BY_STATUS';
export const SET_INVOICE_PDF_TO_HTML = 'SET_INVOICE_PDF_TO_HTML';
export const SET_INVOICE_GUID_TO_DDR_HTML = 'SET_INVOICE_GUID_TO_DDR_HTML';

// actions used by customer panel
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_INVOICES = 'UPDATE_INVOICES';
export const UPDATE_CUSTOMER_DATA = 'UPDATE_CUSTOMER_DATA';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const UPDATE_COMMUNICATIONS = 'UPDATE_COMMUNICATIONS';
export const UPDATE_PAYMENT_OPTIONS = 'UPDATE_PAYMENT_OPTIONS';

export const UPDATE_ALL_ORGANIZATION = "UPDATE_ALL_ORGANIZATION"
export const SET_ALL_ORGANIZATION = "SET_ALL_ORGANIZATION"
export const UPDATE_TREE_ORGANIZATION = "UPDATE_TREE_ORGANIZATION"
export const SET_LOKI = "SET_LOKI";
export const SET_TALLY_GROUPH_GRAPH = "SET_TALLY_GROUPH_GRAPH";
export const SET_PROCESS_EVENT_IN_MEMORY_DATA_STATUS = "SET_PROCESS_EVENT_IN_MEMORY_DATA_STATUS";
export const SET_SYNC_SOURCE_AVAILABLE_FLAG = "SET_SYNC_SOURCE_AVAILABLE_FLAG";
// subscription actions
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTIONONLY = 'UPDATE_SUBSCRIPTIONONLY';
export const UPDATE_ALL_ORGANIZATION_MANAGERS = "UPDATE_ALL_ORGANIZATION_MANAGERS";

//MONTHWISE
export const UPDATE_MONTHWISE_SUMMARY = "UPDATE_MONTHWISE_SUMMARY";
export const UPDATE_MONTHWISE_COLLECTION = "UPDATE_MONTHWISE_COLLECTION";
export const UPDATE_MONTHWISE_OUTSTANDINGS = "UPDATE_MONTHWISE_OUTSTANDINGS";
export const UPDATE_MONTHWISE_OVERALLRTO = "UPDATE_MONTHWISE_OVERALLRTO";

//Stocks
export const UPDATE_STOCK_CATEGORIES = "UPDATE_STOCK_CATEGORIES";
export const UPDATE_STOCK_GROUPS = "UPDATE_STOCK_GROUPS";
export const UPDATE_STOCK_ITEMS = "UPDATE_STOCK_ITEMS"
export const UPDATE_STOCK_ITEMS_INVOICES_BY_ID = "UPDATE_STOCK_ITEMS_INVOICES_BY_ID"
export const UPDATE_STOCK_ITEMS_BILLS_BY_ID = "UPDATE_STOCK_ITEMS_BILLS_BY_ID"
export const UPDATE_STOCK_ITEMS_CUSTOMERS = "UPDATE_STOCK_ITEMS_CUSTOMERS";
export const UPDATE_STOCK_ITEMS_SUPPLIERS = "UPDATE_STOCK_ITEMS_SUPPLIERS";

//INCENTIVES
export const UPDATE_ALL_SCHEMES = "UPDATE_ALL_SCHEMES";
export const UPDATE_ALL_CONTACTS_WITH_SCHEMES = "UPDATE_ALL_CONTACT_WITH_SCHEMES"

//PAY
export const UPDATE_ALL_VENDORS = "UPDATE_ALL_VENDORS";
export const LIST_ALL_VENDOR_PAYMENT_DONE_TALLY = "LIST_ALL_VENDOR_PAYMENT_DONE_TALLY";

//CRM
export const UPDATE_GET_TASKS = "UPDATE_GET_TASKS";

//Get Contact Details 
export const SET_CONTACT_DETAILS = "SET_CONTACT_DETAILS";

//CollectionUI
export const GET_COLLECTION_BILL_STATEMENT = "GET_COLLECTION_BILL_STATEMENT"
export const GET_COLLECTION_TRANSACTIONS = "GET_COLLECTION_TRANSACTIONS"
export const GET_CREATED_EMI_SUMMERY = "GET_CREATED_EMI_SUMMERY"
export const GET_COLLECTION_BILL_STATEMENT_ENABLED = 'GET_COLLECTION_BILL_STATEMENT_ENABLED'
export const GET_COLLECTION_START_DATE_END_DATE = "GET_COLLECTION_START_DATE_END_DATE"
//Get CF-Mart Organization Data
export const CF_MART_ORGANIZATION = "CF_MART_ORGANIZATION";

//DASH BOARD
export const GET_MONTHWISE_SALES = "GET_MONTHWISE_SALES"
export const GET_MONTHWISE_COLLECTION = "GET_MONTHWISE_COLLECTION"
export const GET_MONTHWISE_OUTSTANDING = "GET_MONTHWISE_OUTSTANDING"
export const GET_MONTHWISE_OUTSTANDING_DISTRIBUTION = "GET_MONTHWISE_OUTSTANDING_DISTRIBUTION"
export const GET_MONTHWISE_RECOVERY_DISTRIBUTION = "GET_MONTHWISE_RECOVERY_DISTRIBUTION"
export const GET_CALCULATE_AGEING_SUMMARY_BUCKETWISE = "GET_CALCULATE_AGEING_SUMMARY_BUCKETWISE"
export const GET_DASHBOARD_SUMMARY = "GET_DASHBOARD_SUMMARY"
export const GET_COUNT_INVOICE_DUE_TODAY = "GET_COUNT_INVOICE_DUE_TODAY"
export const GET_COUNT_BILLS_DUE_TODAY = "GET_BILLS_DUE_TODAY"
export const GET_TOP_CUSTOMERS = "GET_TOP_CUSTOMERS"
export const GET_MONTHWISE_PAYMENT_TREND = "GET_MONTHWISE_PAYMENT_TREND"
export const GET_CALCULATE_MONTHWISE_PURCHASE = "GET_CALCULATE_MONTHWISE_PURCHASE"
export const GET_CALCULATE_TOTAL_OUTSTANDING = "GET_CALCULATE_TOTAL_OUTSTANDING"
export const GET_CALCULATE_TOTAL_PAYABLES = "GET_CALCULATE_TOTAL_PAYABLES"
export const GET_CALCULATE_TOTAL_INVENTORY_VALUE = "GET_CALCULATE_TOTAL_INVENTORY_VALUE"
export const GET_CALCULATE_TOTAL_CASH_AND_BANK_BALANCE = "GET_CALCULATE_TOTAL_CASH_AND_BANK_BALANCE"
export const GET_PROJECTED_COLLECTION = "GET_PROJECTED_COLLECTION"
export const GET_REMINDER_SEND_THIS_MONTH = "GET_REMINDER_SEND_THIS_MONTH"
export const GET_BAD_DEBTOR_COUNT = "GET_BAD_DEBTOR_COUNT"
export const GET_USER_DETAILS_DATA = "GET_USER_DETAILS_DATA"
export const GET_TODAYS_TASK_COUNT = "GET_TODAYS_TASK_COUNT"
export const RESET_DASHBOARD_REDUX_DATA = "RESET_DASHBOARD_REDUX_DATA"
export const SET_ORGANIZATION_KYC_DATA = "SET_ORGANIZATION_KYC_DATA"

//Collect screen
export const GET_CONTACT_RECEIPTS = "GET_CONTACT_RECEIPTS"
export const GET_CONTACT_INVOICES = "GET_CONTACT_INVOICES"
export const GET_LEDGER_PDF_DATA_BY_POUCH = "GET_LEDGER_PDF_DATA_BY_POUCH"
export const GET_LEDGER_HTML_DATA = "GET_LEDGER_HTML_DATA"
export const GET_REMINDER_PREFILL_MAIL = "GET_REMINDER_PREFILL_MAIL"
export const GET_REMINDER_MAIL_SUBJECT = "GET_REMINDER_MAIL_SUBJECT"
export const GET_REMINDER_PREFILL_WHATSAPP = "GET_REMINDER_PREFILL_WHATSAPP"
export const GET_REMINDER_PREFILL_SMS = "GET_REMINDER_PREFILL_SMS"
export const GET_DUE_AGEING_SUMMARY_CONTACTWISE = "GET_DUE_AGEING_SUMMARY_CONTACTWISE"
export const GET_RECEIVABLES = "GET_RECEIVABLES";
export const GET_BRANCHES = "GET_BRANCHES";
export const GET_ADVANCEDPAID = "GET_ADVANCEDPAID";
export const GET_CONTACT_CREDIT_NOTES = "GET_CONTACT_CREDIT_NOTES"
export const GET_CONTACT_DEBIT_NOTES = "GET_CONTACT_DEBIT_NOTES"
export const GET_CONTACT_PENDING_PAID_BILLS = "GET_CONTACT_PENDING_PAID_BILLS"
export const GET_BULK_DOWNLOAD_INVOICE = "GET_BULK_DOWNLOAD_INVOICE"
export const SET_BULK_CREDFLOW_DETAILS = "SET_BULK_CREDFLOW_DETAILS"
export const SET_INVOICE_FOR_FINANCE = "SET_INVOICE_FOR_FINANCE"
export const SET_INVOICE_DATA_FOR_INVOICE_FINANCE = "SET_INVOICE_DATA_FOR_INVOICE_FINANCE"
export const SET_COMMUNICATION_HISTORY_FROM_LOKI = "SET_COMMUNICATION_HISTORY_FROM_LOKI"
export const SET_PRIORITY_FLAG = "SET_PRIORITY_FLAG"
export const SET_KYC_ORG_ID = "SET_KYC_ORG_ID"
export const SET_APPLICATION_STATE = "SET_APPLICATION_STATE"
export const SET_LOS_KYC_STATUS = "SET_LOS_KYC_STATUS"
export const SET_NEW_BANNER = "SET_NEW_BANNER"
export const SET_LOS_APPLICATION_ID = "SET_LOS_APPLICATION_ID"
export const SET_LOS_GST = "SET_LOS_GST"
export const SET_LOS_REFRESH = "SET_LOS_REFRESH"
export const SET_SKIP_MANDATE = "SET_SKIP_MANDATE"
export const SET_BUSINESS_TYPE = "SET_BUSINESS_TYPE"
export const SET_LOS_ELIGIBLE_BANK = "SET_LOS_ELIGIBLE_BANK"
export const SET_LOS_DESIRED_LIMIT = "SET_LOS_DESIRED_LIMIT"
export const SET_MANDATE_NACH_STATUS = "SET_MANDATE_NACH_STATUS"
export const SET_USER_JOURNEY_COMPLETED = "SET_USER_JOURNEY_COMPLETED"
export const SET_ALL_ORGANIZATION_USERS = "SET_ALL_ORGANIZATION_USERS"

//Debtor Summary
export const GET_DEBTOR_SUMMARY_REPORT = "GET_DEBTOR_SUMMARY_REPORT"
export const GET_BUSY_TOTAL_SALE = "GET_BUSY_TOTAL_SALE"
export const GET_CONTACTS_RTO = "GET_CONTACTS_RTO"
export const GET_SALES_MANAGER_REPORT = "GET_SALES_MANAGER_REPORT"
export const GET_PORJECTED_COLLECTION_REPORT = "GET_PORJECTED_COLLECTION_REPORT"
export const GET_TAG_VOUCHER_DATA = "GET_TAG_VOUCHER_DATA"

export const SET_INVOICE_PREFILL_SMS = "SET_INVOICE_PREFILL_SMS"
export const SET_INVOICE_PREFILL_WHATSAPP = "SET_INVOICE_PREFILL_WHATSAPP"
export const SET_INVOICE_PREFILL_MAIL = "SET_INVOICE_PREFILL_MAIL"
export const SET_LEDGER_CONTENT_SMS = "SET_LEDGER_CONTENT_SMS"
export const SET_LEDGER_CONTENT_WHATSAPP = "SET_LEDGER_CONTENT_WHATSAPP"
export const GET_SINGLE_DOWNLOAD_INVOICE = "GET_SINGLE_DOWNLOAD_INVOICE"
export const SET_OVERVIEW_OF_SALES_OF_DEBTOR = "SET_OVERVIEW_OF_SALES_OF_DEBTOR";
export const SET_CRM_STAGES_DATA_FOR_CUSTOMER = "SET_CRM_STAGES_DATA_FOR_CUSTOMER";
export const SET_CRM_NOTES = "SET_CRM_NOTES";

//organization 
export const SET_ORGANIZATION_AND_SETTINGS_DETAILS = "SET_ORGANIZATION_AND_SETTINGS_DETAILS";

//pre-approval
export const PRE_APPROVAL_PRODUCT = "PRE_APPROVAL_PRODUCT";
export const PRE_APPROVAL_KUBERX_ID = "PRE_APPROVAL_KUBERX_ID";
export const PRE_APPROVAL_TOKEN = "PRE_APPROVAL_TOKEN";

import axios from "axios";
import { setAllUsers, setOrganizationAndSettingsDetails, updateAuth } from "../actions/user.actions";
import { setTallyGroupGraphForThisOrg, updateTreeOrg } from "../actions/organization.action"
import { API_URL, request ,ODIN_API_URL, getRefreshToken, MJOLNIR_API_URL, CARNAGE_API_URL, WANDA_API_URL, SCEPTER_URL, callBroadCastAsynchronously} from "../helpers/utils";
import { changeSettings } from './settings';

import { store } from "../store";

export async function loginUser(data) {
  let url = `${ODIN_API_URL}/login`;
  const { email, password ,captcha } = data;
  const sendingData = { userName:email, password,captcha};
  return request({
    url: url,
    method: "post",
    data: sendingData,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function changeAccountManager(payload){

  let url = `${WANDA_API_URL}/contact-details/assign-tally-groups-to-user`;

  return request({
    url: url,
    method: "post",
    data: { tallyGroupAssignArray: payload },
    headers: {
      "Content-Type": "application/json",
    },
  })

}

export async function changeAccountManagerToPrimaryAdmin(accountManagerId){

  let url = `${API_URL}/create/assign-tally-groups-to-primary-admin`;

  return request({
    url: url,
    method: "post",
    data: {
      accountManagerId: accountManagerId
    },
    headers: {
      "Content-Type": "application/json",
    },
  })

}

export async function getListOfAssignedTallyGroupsToUser(){

  let url = `${API_URL}/view/get-list-of-tally-groups-assign-to-users`;

  return request({
    url: url,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  })

}

export async function userAuthority() {

  const allOrganizationsData = await getThisOrganizationAndSettingsDetails();
  const selectedOrgId = window.localStorage.getItem("organizationId");
  let selectedOrg  = allOrganizationsData.find(org=> org?.organization?.id == selectedOrgId);

  return {
    data: selectedOrg?.userPermissions,
    status: 200
  }

  let url = `${MJOLNIR_API_URL}/authorities/get-user-authorities`;
  return request({
    url,
    method: "get",
  });
}

export async function getTallyGroupGraphs() {
  const state = store.getState();
  const { organization } = state;
  if(organization.tallyGroupGraph){
    return organization.tallyGroupGraph?.[0];
  }
  let response = await callBroadCastAsynchronously("getTallyGroupGraph");
  store.dispatch(setTallyGroupGraphForThisOrg(response))
  return response?.[0];
}

export async function getNewAccessToken(_cancelToken) {
  let url = `${ODIN_API_URL}/renew-token`;
  const refreshToken = getRefreshToken();
  return axios({
    url: url,
    method: "get",
    headers: {
      "Authorization": refreshToken,
    },
    cancelToken: _cancelToken
  });
}

export async function getTree(data) {
  let url = `${API_URL}/view/overallDueAgeingSummary/gettreeOfOrg`;
  const state = store.getState();
  const { treeOrganization } = state;
  if (treeOrganization?.treeOfOrg && treeOrganization?.treeOfOrg?.data?.length) {
    return treeOrganization.treeOfOrg;
  }
  const response = await callBroadCastAsynchronously('getTreeOfOrg', {});
  const returnedData =  {
    data: response,
    status: 200
  };
  store.dispatch(updateTreeOrg(returnedData));
  
  return returnedData;
}
export async function dailyReportApi(data, is_email, is_whatsapp) {
  let url = `${API_URL}/create/dailyReport/${data}`;

  return request({
    url: url,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      is_email,
      is_whatsapp,
    },
  });
}

export async function dailyReportChangeApi(days, orgID) {
  return changeSettings([
    {
        "context": "organization",
        "attribute": "DailyDays",
        "newValue": days
    }
  ])
}

export async function weeklyReportApi(data, is_email, is_whatsapp) {
  let url = `${API_URL}/create/weeklyReport/${data}`;

  return request({
    url: url,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      is_email,
      is_whatsapp,
    },
  });
}

export async function deleteWeeklyReportApi(data, is_email, is_whatsapp) {
  let url = `${API_URL}/create/deleteWeeklyReport/${data}`;

  return request({
    url: url,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      is_email,
      is_whatsapp,
    },
  });
}

export async function getAllOrganizationOnce() {
  let url = `${API_URL}/view/AllOrgDetails`;

  return request({
    url: url,
    method: "get",
    // data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getApiOrderChange(data) {
  return changeSettings([
    {
        "context": "organization",
        "attribute": "order",
        "newValue": data
    }
  ])
}
export async function getApiInvoiceChange(data) {
    return changeSettings([
      {
          "context": "userOrganization",
          "attribute": "invoiceStart",
          "newValue": data
      }
    ])
}

export async function getApiTimeChange(data) {
  return changeSettings([
    {
        "context": "organization",
        "attribute": "timing",
        "newValue": data
    }
  ])
}

export async function monthlyReportApi(id, time) {
  const data = {
    userId: id,
    timing: time,
  };
  let url = `${API_URL}/create/monthlyReport`;

  return request({
    url: url,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function uploadAccountManger(data) {
  let url = `${API_URL}/create/uploadAccountManger`;

  return request({
    url: url,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteDailyReportApi(data, is_email, is_whatsapp) {
  let url = `${API_URL}/create/deleteDailyReport/${data}`;

  return request({
    url: url,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      is_email,
      is_whatsapp,
    },
  });
}

export async function logoutUser(data = {}) {
  // let url = `${API_URL}/user/logout`;
  let url = `${ODIN_API_URL}/logout`;

  return request({
    url: url,
    method: "get",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function registerUser(data) {
  let url = `${MJOLNIR_API_URL}/register`;

  return request({
    url: url,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function registerInviteUser(data) {
  let url = `${MJOLNIR_API_URL}/register-invite-user`;

  return request({
    url: url,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function resetPassword(data) {
  let url = `${MJOLNIR_API_URL}/forgot-password`;

  return request({
    url: url,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updatePassword(data) {
  let url = `${MJOLNIR_API_URL}/reset-password`;
  return request({
    url: url,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getOrgDetails(updateForce = false) {
  // Fetching data from new Api
  
  const allOrganizationsData = await getThisOrganizationAndSettingsDetails();
  const selectedOrgId = window.localStorage.getItem("organizationId");
  const selectedOrgsDetails  = allOrganizationsData.find(org=> org?.organization?.id == selectedOrgId);
  const organizationDetails = selectedOrgsDetails?.organization;
  
  return {
    data: organizationDetails,
    status: 200
  };
  
  /**
   * deprecated old code
  */

  let url = `${API_URL}/view/orgdetails`;
  const state = store.getState();
  const { organization } = state;
  if (Object.keys(organization).length && updateForce === false) {
    return {'data':state.organization}
  } else {
    return request({
      url: url,
      method: "get",
      headers: {},
    });
  }
}


export async function getOrganizationSettings(updateForce = false) {

  const allOrganizationsData = await getThisOrganizationAndSettingsDetails(updateForce);
  const selectedOrgId = window.localStorage.getItem("organizationId");
  const selectedOrgsDetails  = allOrganizationsData.find(org=> org?.organization?.id == selectedOrgId);
  const organizationSettings = selectedOrgsDetails?.organizationSettings;

  return {
    data: organizationSettings,
    status: 200
  }

	let url = `${API_URL}/view/organizationSettings`;
	const state = store.getState();
	const { organization } = state;
	if (organization.sendBadDebtReminder && updateForce === false) {
		return { data: state.organization };
	} else {
		return request({
			url: url,
			method: 'get',
			headers: {},
		});
	}
}

export async function switchOrganization(id) {
  let url = `${API_URL}/view/switchOrganization/${id}`;

  return request({
    url: url,
    method: "get",
    headers: {},
  });
}

export async function getAllOrganization(){
  let url = `${MJOLNIR_API_URL}/organization/list-all`;
  const state = store.getState()
  const { allOrganization } = state;

  if (Object.keys(allOrganization).length) {
    return state.allOrganization;
  } else {
    return request({
      url: url,
      method: "get",
    })
  }
}

export async function getAllUsers() {
  const state = store.getState()
  const { auth } = state;
  let url = `${API_URL}/view/allusers`;
  if(auth?.allUsers && auth?.allUsers?.data?.length){
    return auth.allUsers
  }
  let response = await request({
    url: url,
    method: "get",
    headers: {},
  });
  let newResponse = {
    data : response.data,
    status: response.status
  }
  store.dispatch(setAllUsers(newResponse));
  return newResponse
}
export async function sendOrganizationAccessUser(data) {
  let url = `${API_URL}/view/changeOrganization`;

  return request({
    url: url,
    method: "post",
    data: { data },
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function sendOrganizationAccessUserName(data) {
  return changeSettings([
    {
        "context": "organization",
        "attribute": "modifiedName",
        "newValue": data.modifiedName
    }
  ])
}
export async function getThisUser() {
  let url = `${API_URL}/view/user`;
  const state = store.getState();
  const { user } = state.auth;
  if (user) return { data: user, status: 200};
  return request({
    url: url,
    method: "get",
    headers: {}
  });
}
export async function getVendorCommunication() {
  let url = `${SCEPTER_URL}/setting/get-vendor-details`;
  return request({
    url: url,
    method: "get",
    headers: {}
  });
}

export async function submitHelpForm(mailContent) {
  let url = `${API_URL}/create/sendhelpmail`;

  return request({
    url: url,
    method: "post",
    data: { content: mailContent },
    headers: {},
  });
}
export async function getApiBucketChange(id, send) {
  return changeSettings([
    {
        "context": "userOrganization",
        "attribute": "bucketSize",
        "newValue": send
    }
  ])
}
export async function getAllUsers2(orgId) {
  let url = `${API_URL}/view/allusers/${orgId}`;

  return request({
    url: url,
    method: "get",
    headers: {},
  });
}

export async function updateOrgDetails(data) {
  let url = `${API_URL}/create/updateOrgDetails`;

  return request({
    url: url,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updateTagUserOrganization(id, data) {
  let url = `${API_URL}/create/updateUserDetails/${id}`;

  return request({
    url: url,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function sendInviteUser(data) {
  let url = `${MJOLNIR_API_URL}/invite-user`;

  return request({
    url: url,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getAllPendingUsers(data) {
  let url = `${API_URL}/view/PendingUsers/${data.orgID}`;

  return request({
    url: url,
    method: "get",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getActiveLoggedInUsers(data) {
  let url = `${MJOLNIR_API_URL}/active-users`;

  return request({
    url: url,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function logOutFromAccount(data) {
  let url = `${MJOLNIR_API_URL}/remove-user`;

  return request({
    url: url,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteUser(data) {
  let url = `${MJOLNIR_API_URL}/authorities/delete-user`;
  return request({
    url: url,
    method: "post",
    data: {
      userIdToBeDeleted:data.userId
    },
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function confirmation() { }

export async function resendVerification(data) {
  let url = `${API_URL}/user/resendVerify`;

  return request({
    url: url,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function changeName(data) {
  let url = `${API_URL}/user/changeName`;

  return request({
    url: url,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function changePhone(data) {
  let url = `${API_URL}/user/changePhone`;

  return request({
    url: url,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}


export async function sendOtpToMobileNumber(data){
  let url = `${MJOLNIR_API_URL}/authorities/send-otp-to-mobile`;

  return request({
    url: url,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function verifyOtpToUpdateMobileNumber(data){
  let url = `${MJOLNIR_API_URL}/authorities/verify-otp-update-mobile`;

  return request({
    url: url,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}


export async function changeWhatsapp(data) {
  let url = `${API_URL}/user/changeWhatsapp`;

  return request({
    url: url,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function changePassword(data) {
  let url = `${API_URL}/user/changePassword`;

  return request({
    url: url,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function requestEmailChange(data) {
  let url = `${API_URL}/user/requestEmailChange`;

  return request({
    url: url,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function changeLedgerClassificationForThisOrg(classificationType) {
  let url = `${API_URL}/create/changeLedgerClassification`;

  return request({
    url: url,
    method: "post",
    data: {
      type: classificationType,
    },
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function changeIncludeInvoiceInMsgReminderForThisOrg(ele) {
  return changeSettings([
    {
        "context": "organization",
        "attribute": "sendInvoiceInMsgReminder",
        "newValue": ele
    }
  ])
}

export async function changeIncludeInvoiceInReminderForThisOrg(ele) {
  return changeSettings([
    {
        "context": "userOrganization",
        "attribute": "sendInvoiceInReminder",
        "newValue": ele
    }
  ])
}

export async function updateCCSettings(options) {
  return changeSettings([
    {
      "context": "organization",
      "attribute": "ccAccountManager",
      "newValue": options.ccAccountManager
    },
    {
        "context": "organization",
        "attribute": "ccAdmin",
        "newValue": options.ccAdmin
    }
  ])
}

export async function toggleShortPaymentReminder(options) {
  return changeSettings([
    {
        "context": "organization",
        "attribute": "sendShortAmountReminder",
        "newValue": options?.sendShortAmountReminder
    }
  ])
}

export async function toggleShowLedgerInReminder(options) {
  return changeSettings([
    {
        "context": "settings",
        "attribute": "showLedgerInReminders",
        "newValue": options.showLedgerInReminders
    }
  ])
}

export async function toggleBadDebtReminder(options) {
  return changeSettings([
    {
        "context": "settings",
        "attribute": "sendBadDebtReminder",
        "newValue": options?.sendBadDebtReminder || false
    }
  ])
}


export async function toggleRequireReceiptApproval(options) {
  let url = `${API_URL}/create/toggleRequireReceiptApproval`;

  return request({
    url: url,
    method: "post",
    data: options,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updateBadDebtOverdueThreshold(options) {
  return changeSettings([
    {
      "context": "organization",
      "attribute": "badDebtOverdueThreshold",
      "newValue": options.badDebtOverdueThreshold
    }
  ])
}

export async function announcementsFeatures(){
  let url = `${API_URL}/view/announcements`;
  return request({
    url:url,
    method:"get"
  })
}

export async function getAutoSharingSettings(){
  let url =`${MJOLNIR_API_URL}/organization/get-auto-sharing-details`;
  return request({
    url,
    method:"get"
  })
}

export async function setAutoSharingSettings(data){
  let url = `${MJOLNIR_API_URL}/organization/update-org-reminder-time`
  return request({
    url: url,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  })
}

export async function getOrganizationSettingFlag(){

  const allOrganizationsData = await getThisOrganizationAndSettingsDetails();
  const selectedOrgId = window.localStorage.getItem("organizationId");
  const selectedOrgsDetails  = allOrganizationsData.find(org=> org?.organization?.id == selectedOrgId);
  
  return {
    data: {
      isReferralPromotionEnabled: selectedOrgsDetails?.organizationSettings?.isReferralPromotionEnabled,
      sendTodaysInvoices: selectedOrgsDetails?.organizationSettings?.sendTodaysInvoices
    },
    status: 200
  }

  let url =`${MJOLNIR_API_URL}/organization/get-org-setting-flags`;
  return request({
    url,
    method:"get"
  })

}

export async function setOrganizationSettingFlag(data){
  let url =`${MJOLNIR_API_URL}/organization/update-org-setting-flags`;
  return request({
    url: url,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  })
}

export async function getAutoSharingFlags() {
	let url = `${SCEPTER_URL}/get-auto-sharing-flags`;
	return request({
    url,
    method:"get"
  })
}
export async function getOrgReminderTime() {
	let url = `${MJOLNIR_API_URL}/organization/get-org-reminder-time`;
	return request({
    url,
    method:"get"
  })
}

export async function updateLedgerSharingDate(data){
  let url =`${SCEPTER_URL}/update-ledger-autoshare-settings`;
  return request({
    url: url,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  })
}
export async function updateLedgertoggleAutoShare(data){
  let url =`${SCEPTER_URL}/update-ledger-autoshare-settings`;
  return request({
    url: url,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  })
}

export async function updateBillingDetails(data){
  let url = `${API_URL}/user/updateBillingDetails`;
  return request({
    url: url,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  })
}

export async function getThisOrganizationAndSettingsDetails(checkUpdated = false) {
  const state = store.getState();
  const { auth } = state;
  if (auth && auth.organizationAndSettingsDetails && checkUpdated == false) {
    return auth.organizationAndSettingsDetails;
  } else {
    let url = `${SCEPTER_URL}/organization-details`;
    let response = await request({
      url: url,
      method: "get",
      headers: {},
    });

    await store.dispatch(setOrganizationAndSettingsDetails(response.data));
    return response.data;
  }
}
import { GET_CALCULATE_TOTAL_CASH_AND_BANK_BALANCE, GET_CALCULATE_TOTAL_INVENTORY_VALUE, GET_CALCULATE_TOTAL_PAYABLES, GET_COUNT_INVOICE_DUE_TODAY,GET_MONTHWISE_PAYMENT_TREND, GET_TOP_CUSTOMERS,GET_CALCULATE_TOTAL_OUTSTANDING, GET_MONTHWISE_RECOVERY_DISTRIBUTION, GET_CALCULATE_AGEING_SUMMARY_BUCKETWISE, GET_DASHBOARD_SUMMARY, GET_MONTHWISE_COLLECTION, GET_MONTHWISE_OUTSTANDING, GET_MONTHWISE_OUTSTANDING_DISTRIBUTION, GET_MONTHWISE_SALES, GET_CALCULATE_MONTHWISE_PURCHASE, GET_PROJECTED_COLLECTION, GET_REMINDER_SEND_THIS_MONTH, GET_BAD_DEBTOR_COUNT, GET_USER_DETAILS_DATA, GET_TODAYS_TASK_COUNT, RESET_DASHBOARD_REDUX_DATA, SET_ORGANIZATION_KYC_DATA, PRE_APPROVAL_PRODUCT, PRE_APPROVAL_KUBERX_ID, PRE_APPROVAL_TOKEN } from '../constants';

const initialState = {
}

export const getDashBoardData = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_MONTHWISE_SALES:
            return { ...state, monthWiseSales: action.payload }
        case GET_MONTHWISE_COLLECTION:
            return { ...state, monthWiseCollections: action.payload }
        case GET_MONTHWISE_OUTSTANDING: 
            return { ...state, monthWiseOutStaning: action.payload }
        case GET_MONTHWISE_OUTSTANDING_DISTRIBUTION:
            return { ...state, monthWiseOutStaningDistribution: action.payload }
        case GET_MONTHWISE_RECOVERY_DISTRIBUTION:
            return { ...state, monthWiseTimeRecoveryDistribution: action.payload }
        case GET_CALCULATE_AGEING_SUMMARY_BUCKETWISE:
            return { ...state, calculateAgeingSummaryBucketWise: action.payload }
        case GET_DASHBOARD_SUMMARY:
            return { ...state, dashBoardSummary: action.payload}
        case GET_COUNT_INVOICE_DUE_TODAY:
            return { ...state, countInvoicesDueToday: action.payload}
        case GET_TOP_CUSTOMERS:
            return { ...state, getTopCustomers: action.payload}
        case GET_MONTHWISE_PAYMENT_TREND:
            return { ...state, monthwisePaymentTrend: action.payload}
        case GET_CALCULATE_MONTHWISE_PURCHASE:
            return { ...state, calculateMonthwisePurchases: action.payload}
        case GET_CALCULATE_TOTAL_OUTSTANDING:
            return { ...state, calculateTotalOutstanding: action.payload}
        case GET_CALCULATE_TOTAL_PAYABLES:
            return { ...state, calculateTotalPayables: action.payload}
        case GET_CALCULATE_TOTAL_INVENTORY_VALUE:
            return { ...state, calculateTotalInventoryValue: action.payload}
        case GET_CALCULATE_TOTAL_CASH_AND_BANK_BALANCE:
            return { ...state, calculateTotalCashAndBankBalance: action.payload}
        case GET_PROJECTED_COLLECTION:
            return { ...state, projectedCollection: action.payload }
        case GET_REMINDER_SEND_THIS_MONTH:
            return { ...state, reminderSendThisMonth: action.payload}
        case GET_BAD_DEBTOR_COUNT:
            return { ...state, badDebtorsCount: action.payload}
        case GET_USER_DETAILS_DATA:
            return { ...state, userDetailsData: action.payload}
        case GET_TODAYS_TASK_COUNT:
            return { ...state, todaysTaskCount: action.payload}
        case SET_ORGANIZATION_KYC_DATA:
            return { ...state, kycData: action.payload }
        case RESET_DASHBOARD_REDUX_DATA:
            return {}
        case PRE_APPROVAL_PRODUCT:
            return { ...state, preApprovalProduct: action.payload }
        case PRE_APPROVAL_KUBERX_ID:
            return { ...state, preApprovalKuberxId: action.payload }
        case PRE_APPROVAL_TOKEN:
            return { ...state, preApprovalToken: action.payload }
        default:
            return state;
    }
}
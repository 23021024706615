import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import FallBack from "./components/FallBack";
import loadable from '@loadable/component';
import { isAuthenticated } from "./helpers/utils";
import WorkerListener from "./WorkerListener";

const LoadableProductLayout = loadable(() => import('./AuthenticatedAppLayout'));
const LoadableCustomerAppLayout = loadable(() => import('./CustomerAppLayout'));
const LoadableAuthenticatorLayout = loadable(() => import('./AuthenticatorLayout'));

const isAuth = isAuthenticated();

const AppRouter = ({ history }) => {

  const queryParams = history?.location?.search;
  return (
    <Suspense fallback={<FallBack />}>
      <WorkerListener />
      <Router history={history}>
        <Switch>

          {/* 30-12-2021 */}
          {/* Code for Customer(Debtor) Web Application is being migrated from black-widow to valkyrie */}
          {/* The following logic will point any request towards black-widow to valkyrie */}
          {
            history?.location?.pathname === "/customer" ? (
              window.open(`${process.env.DEBTOR_APP_BASE_URL}/all-invoices` + queryParams, "_self")
            ) : (history?.location?.pathname === "/customer/invoice") ? (
              window.open(`${process.env.DEBTOR_APP_BASE_URL}/invoice` + queryParams, "_self")
            ) : (history?.location?.pathname === "/customer/createInvoice") ? (
              window.open(`${process.env.DEBTOR_APP_BASE_URL}/customer-invoice` + queryParams, "_self")
            ) : history?.location?.pathname === "/customer/createSalesOrder" ? (
              window.open(`${process.env.DEBTOR_APP_BASE_URL}/custom-sales-order` + queryParams, "_self")
            ) : history?.location?.pathname === "/customer/quotation" ? (
              window.open(`${process.env.DEBTOR_APP_BASE_URL}/quotation` + queryParams, "_self")
            ) : history?.location?.pathname === "/customer/ledger" ? (
              window.open(`${process.env.DEBTOR_APP_BASE_URL}/ledger` + queryParams, "_self")
            ) : history?.location?.pathname === "/customer/payScreen" ? (
              window.open(`${process.env.DEBTOR_APP_BASE_URL}/pay` + queryParams, "_self")
            ) : history?.location?.pathname === "/customer/sentInvoices" ? (
              window.open(`${process.env.DEBTOR_APP_BASE_URL}/sent-invoice` + queryParams, "_self")
            ) : null
          }
          <Route path="/customer" component={LoadableCustomerAppLayout} />
          {isAuth ? (
              <Route path="/" component={LoadableProductLayout} />
            ) : (
              <Route path="/" component={LoadableAuthenticatorLayout} />
            )}
        </Switch>
      </Router>
    </Suspense>
  );
};

export default AppRouter;
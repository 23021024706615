import {
  UPDATE_RECEIPTS,
  UPDATE_RECEIPT_CUSTOMERS,
  UPDATE_VOUCHERS,
  APPROVE_RECEIPT,
  DISAPPROVE_RECEIPT,
  SET_ACCOUNTS,
  SET_ALL_RECEIPTS,
  SET_RECEIPT_PREFILL_CONTENT,
  SET_VOUCHERS_BY_STATUS,
  SET_INVOICE_PDF_TO_HTML,
  SET_INVOICE_GUID_TO_DDR_HTML
} from '../constants';


export const updateCustomers = (customers) => ({
  type: UPDATE_RECEIPT_CUSTOMERS,
  payload: customers
});

export const updateReceipts = (data) => ({
  type: UPDATE_RECEIPTS,
  payload: data
  // data contains receipts and invoiceReceipts
});


export const updateVouchers = (vouchers) => ({
  type: UPDATE_VOUCHERS,
  payload: vouchers
});

export const approveReceipt = (id) => ({
  type: APPROVE_RECEIPT,
  payload: id
});

export const disapproveReceipt = (id) => ({
  type: DISAPPROVE_RECEIPT,
  payload: id
});

export const setAccounts = (accounts) => ({
  type: SET_ACCOUNTS,
  payload: accounts,
})
export const setAllReceipts = (data) => ({
  type: SET_ALL_RECEIPTS,
  payload: data
});

export const setPrefillContent = (data) => ({
  type: SET_RECEIPT_PREFILL_CONTENT,
  payload: data
});

export const setVouchersByStatus = (vouchersByStatus) => ({
  type: SET_VOUCHERS_BY_STATUS,
  payload: vouchersByStatus
}) 
export const setInvoicePDftoHTML = (invoicePDftoHTML) => ({
  type: SET_INVOICE_PDF_TO_HTML,
  payload: invoicePDftoHTML
})

export const setMapInvoiceGuidToDdrHtml = (mapInvoiceGuidToDdrHtml) => ({
  type: SET_INVOICE_GUID_TO_DDR_HTML,
  payload: mapInvoiceGuidToDdrHtml
})

const getScreeName = (url) => {

    const screens = {
        'login' : 'login',
        'dashboard' : 'dashboard',
        'paymentRecovery/all' : 'collect',
        'paymentRecovery/ledger' : 'ledger',
        'ContactReport' : 'debtor summary',
        'overallReport' : 'sales manager report',
        'ProjectedCollectionReport' : 'projected collection',
        'crm' : 'crm',
        'incentive' : 'incentives',
        'outbox' : 'communication',
        'vendors/all' : 'pay',
        'vendors/ledger' : 'vendor ledger',
        'invoices/all' : 'invoices',
        'invoices/ledger' : 'invoice ledger',
        'paymentsReceived' : 'receipts',
        'customer-ledger' : 'customer ledger',
        'creditNotes' : 'credit notes',
        'receipts' : 'create receipt',
        'bills' : 'bills',
        'paymentDone': 'payment done',
        'debitNotes' : 'debit notes',
        'stockItems/all':'stock items',
        'stockItems/itemSummary' : 'item summary',
        'pdc/receivable':'pdc received',
        'pdc/payable' : 'pdc given',
        'Sync' : 'sync',
        'user-permission':'user permission',
        'profile': 'profile',
        'subscription':'subscription',
        'receiptScreen': 'receipt screen',
        'paymentSettings': 'payment settings',
        'customer/invoice': 'customer invoice',
        'customer/quotations': 'customer quotations',
        'customer/ledger': 'customer ledger',
        'customer/incentives': 'customer incentives',
        'customer/communications': 'customer communications',
        'customer/why': 'customer why',
        'customer/payScreen/netBanking': 'customer payScreen netBanking',
        'customer/payScreen/upi': 'customer payScreen upi',
        'customer/payScreen/neft-rtgs': 'customer payScreen NEFT-RTGS',
        'customer/payScreen/card': 'customer payScreen card',
        'customer/payScreen/success': 'customer payScreen success',
        'customer/payScreen/customer-data' : 'customer payScreen customer data',
        'customer/payScreen': 'customer payScreen',
        'customer': 'customer',
    }

    for(let x in screens){
        if(url.includes(x)) return screens[x];
    }
    return '';
}

export default getScreeName;

import {UPDATE_AUTH, SET_ALL_USERS, SET_ORGANIZATION_AND_SETTINGS_DETAILS} from '../constants';

const initialState = {
  user: null
}

export const auth = (state = initialState, action = {}) => {
  switch(action.type) {
    case UPDATE_AUTH:
      return { ...state, user: action.payload,}
    case SET_ALL_USERS:
      return { ...state, allUsers: action.payload}
    case SET_ORGANIZATION_AND_SETTINGS_DETAILS:
      return { ...state, organizationAndSettingsDetails: action.payload}
    default:
      return state;
  }
}
import { KUBER_API_URL, SCEPTER_URL, request } from "../helpers/utils";

export async function pendingGSTApplications() {
    let url = `${KUBER_API_URL}/los/pending-applications`;
    return request({
      url,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
}

export async function getGstDetails(gstNo, manualGstNo = "") {
    let url = `${KUBER_API_URL}/los/get-gst-details`;
    return request({
      url,
      method: "GET",
      headers: {
        "gstNumber": gstNo,
        "manualGstNumber": manualGstNo,
        "Content-Type": "application/json"
      }
    })
}

export async function getOrgPan(gstNo) {
    let url = `${KUBER_API_URL}/los/get-organization-pan`;
    return request({
      url,
      method: "GET",
      headers: {
        "gstNumber": gstNo,
        "Content-Type": "application/json"
      }
    })
}

export async function getPanDetails(gstNo, manualPanNo = "") {
    let url = `${KUBER_API_URL}/los/get-pan-details-validation`;
    return request({
      url,
      method: "GET",
      headers: {
        "gstNumber": gstNo,
        "manualPanNumber": manualPanNo,
        "Content-Type": "application/json"
      }
    })
}

export async function panOtpVerify(gstNo, mobileNo = "", otpNo = "") {
    let url = `${KUBER_API_URL}/los/verify-pan-otp`;
    return request({
        url,
        method: "POST",
        data:{mobileNumber: mobileNo, otp: otpNo},
        headers: {
            "gstNumber": gstNo,
            "Content-Type": "application/json"
        }
    })
}

export async function getBusinessAddress(gstNo) {
    let url = `${KUBER_API_URL}/los/get-bussiness-address`;
    return request({
        url,
        method: "GET",
        headers: {
        "gstNumber": gstNo,
        "Content-Type": "application/json"
        }
    })
}

export async function updateBusinessAddress(gstNo, payload) {
    let url = `${KUBER_API_URL}/los/update-bussiness-address`;
    return request({
        url,
        method: "POST",
        data: payload,
        headers: {
        "gstNumber": gstNo,
        "Content-Type": "application/json"
        }
    })
}

export async function getResidentialAddress(gstNo) {
    let url = `${KUBER_API_URL}/los/get-residential-address`;
    return request({
        url,
        method: "GET",
        headers: {
        "gstNumber": gstNo,
        "Content-Type": "application/json"
        }
    })
}

export async function updateResidentialAddress(gstNo, payload) {
    let url = `${KUBER_API_URL}/los/update-residential-address`;
    return request({
        url,
        method: "POST",
        data: payload,
        headers: {
            "gstNumber": gstNo,
            "Content-Type": "application/json"
        }
    })
}

export async function kycStart(gstNo) {
    let url = `${KUBER_API_URL}/los/get-kyc`;
    return request({
        url,
        method: "GET",
        headers: {
        "gstNumber": gstNo,
        "Content-Type": "application/json"
        }
    })
}

export async function uploadlosDocuments(gstNo, formData) {
  let url = `${KUBER_API_URL}/los/manual-kyc`;
  return request({
    url,
    method: "POST",
    data: formData,
    headers: {
      "gstNumber": gstNo
    }
  })
}

export async function getKYCStatus(gstNo) {
  let url = `${KUBER_API_URL}/los/get-kyc-status`;
  return request({
    url,
    method: "GET",
    headers: {
      "gstNumber": gstNo,
      "Content-Type": "application/json"
    }
  })
}

export async function getPreApprovedLimit(gstNo) {
  let url = `${KUBER_API_URL}/los/get-pre-appoved-limit`;
  return request({
    url,
    method: "GET",
    headers: {
      "gstNumber": gstNo,
      "Content-Type": "application/json"
    }
  })
}

export async function getLosTandC() {
  let url = `${KUBER_API_URL}/los/toc`;
  return request({
    url,
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
}

export async function getLosDataPolicy() {
  let url = `${KUBER_API_URL}/los/data-policy`;
  return request({
    url,
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
}

export async function signAgreement(gstNo, requestAmount) {
  let url = `${KUBER_API_URL}/los/sign-agreement`;
  return request({
    url,
    method: "POST",
    data: { requestAmount },
    headers: {
      "gstNumber": gstNo,
      "Content-Type": "application/json"
    }
  })
}

export async function getSignAgreementStatus(gstNo) {
  let url = `${KUBER_API_URL}/los/get-sign-agreement-status`;
  return request({
    url,
    method: "GET",
    headers: {
      "gstNumber": gstNo,
      "Content-Type": "application/json"
    }
  })
}
export async function eMandateBankDetails(gstNo, payload) {
  let url = `${KUBER_API_URL}/los/mandate-bank`;
  return request({
    url,
    method: "POST",
    data: payload,
    headers: {
        "gstNumber": gstNo,
        "Content-Type": "application/json"
    }
  })
}

export async function updateConsentData(gstNo, payload) {
  let url = `${KUBER_API_URL}/los/consent`;
  return request({
    url,
    method: "POST",
    data: payload,
    headers: {
        "gstNumber": gstNo,
        "Content-Type": "application/json"
    }
  })
}

export async function getApplicationInfo() {
  let url = `${KUBER_API_URL}/los/get-application-info`;
  return request({
    url,
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
}

export async function getIndividualPanDetails(gstNo, manualPanNo = "") {
  let url = `${KUBER_API_URL}/los/get-individual-pan-details-validation`;
  return request({
    url,
    method: "GET",
    headers: {
      "gstNumber": gstNo,
      "manualPanNumber": manualPanNo,
      "Content-Type": "application/json"
    }
  })
}

export async function panIndividualOtpVerify(gstNo, mobileNo = "", otpNo = "") {
  let url = `${KUBER_API_URL}/los/verify-individual-pan-otp`;
  return request({
      url,
      method: "POST",
      data:{mobileNumber: mobileNo, otp: otpNo},
      headers: {
          "gstNumber": gstNo,
          "Content-Type": "application/json"
      }
  })
}

export async function getHowItWorkConstants() {
  let url = `${KUBER_API_URL}/los/constants`;
  return request({
      url,
      method: "GET",
      headers: {
          "Content-Type": "application/json"
      }
  })
}

export async function getAdditionalDocuments(gstNo) {
  let url = `${KUBER_API_URL}/los/get-additional-documents`;
  return request({
    url,
    method: "GET",
    headers: {
      "gstNumber": gstNo,
      "Content-Type": "application/json"
    }
  })
}

export async function uploadAdditionalDocuments(gstNo, formData) {
  let url = `${KUBER_API_URL}/los/upload-additional-document`;
  return request({
    url,
    method: "POST",
    data: formData,
    headers: {
      "gstNumber": gstNo,
      "Content-Type": "application/json"
    }
  })
}

export async function updateEligibleBanks(gstNo, banks) {
  let url = `${KUBER_API_URL}/los/update-eligible-banks`;
  return request({
    url,
    method: "POST",
    data: banks,
    headers: {
      "gstNumber": gstNo,
      "Content-Type": "application/json"
    }
  })
}

export async function getEligibleBanks(gstNo) {
  const url = `${KUBER_API_URL}/los/get-eligible-banks`;
  return request({
    url,
    method: "GET",
    headers: {
      "gstNumber": gstNo,
      "Content-Type": "application/json"
    }
  })
}

export async function getEligibleBankFromLoki() {
  if(LOKI_IN_MEMORY) {
    broadcast.postMessage({
      method: 'getEligibleBanks',
      arguments: {},
      tabId: sessionStorage.tabID
    });
    return "POUCHDB"
  }
}

export async function getMandateAuthStatus(gstNo) {
  let url = `${KUBER_API_URL}/los/get-mandate-auth-status`;
  return request({
    url,
    method: "GET",
    headers: {
        "gstNumber": gstNo,
        "Content-Type": "application/json"
    }
  })
}

export async function updateRequestAmount(gstNo, requestAmount) {
  let url = `${KUBER_API_URL}/los/update-request-amount`;
  return request({
    url,
    method: "POST",
    data: requestAmount,
    headers: {
      "gstNumber": gstNo,
      "Content-Type": "application/json"
    }
  })
}

export async function prefillMandateBank(gstNo) {
  let url = `${KUBER_API_URL}/los/prefill-mandate-bank`;
  return request({
    url,
    method: "GET",
    headers: {
        "gstNumber": gstNo,
        "Content-Type": "application/json"
    }
  })
}

export async function retryNachApi(gstNo) {
  let url = `${KUBER_API_URL}/los/update-mandate-auth-status`;
  return request({
    url,
    method: "GET",
    headers: {
        "gstNumber": gstNo,
        "Content-Type": "application/json"
    }
  })
}

export async function getCardData() {
  let url = `${KUBER_API_URL}/los/card-view`;
  return request({
    url,
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
}

export async function sendKycCommunication() {
  const url = `${KUBER_API_URL}/los/send-kyc-communication`;

  return request({
    url,
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
}

export async function createLsqLead(data, preApprovalToken) {
  let url = `${KUBER_API_URL}/lead-squared`;
  const headers = {
    "Content-Type": "application/json"
  }
  if(preApprovalToken) headers.Authorization = preApprovalToken;
  return request({
    url,
    method: "POST",
    data,
    headers
  })
}

export async function gstReportRequest(data) {
  const url = `${SCEPTER_URL}/gst-report-creation`;
  return request({
    url,
    method: "POST",
    data,
    headers: {
      "Content-Type": "application/json"
    }
  })
}

export async function gstReportSubmitOtp(data) {
  const url = `${SCEPTER_URL}/gst-report-submit-otp`;
  return request({
    url,
    method: "POST",
    data,
    headers: {
      "Content-Type": "application/json"
    }
  })
}

import { MJOLNIR_API_URL, ODIN_API_URL, request } from "../helpers/utils";


export async function validateOrbitSignup(token, clientId) {
    return request({
        url: `${ODIN_API_URL}/ext-login?clientId=${clientId}`,
        method: 'get',
        headers: {
            Authorization: token
        }
    })
}

export async function generateConciliatorToken() {
    return request({
        url: `${MJOLNIR_API_URL}/authorities/get-conciliator-token`,
        method: 'get',
    })
}

export async function getConciliatorUrl() {
    return request({
        url: `${MJOLNIR_API_URL}/get-conciliator-url`,
        method: 'get'
    });
}
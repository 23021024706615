import axios from "axios";
import { SCEPTER_URL, request } from "../helpers/utils";


export async function changeSettings(payload){
  
    return request({
      url: `${SCEPTER_URL}/update-org-settings`,
      method: "PUT",
      data: { settings: payload },
      headers: {
        "Content-Type": "application/json",
      },
    })
}

export async function getUploadSignedUrl(type){
  return request({
    url: `${SCEPTER_URL}/upload-signed-url/${type}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
}

export async function uploadSignedUrlFile(signedUrl, fileToUpload, contentType){
  return axios.put(signedUrl, fileToUpload, {headers:{ 'Content-Type': contentType}});
}

export async function uploadSignedUrl(type){
  return request({
    url: `${SCEPTER_URL}/logo-sign`,
    method: "PUT",
    data: { type },
    headers: {
      "Content-Type": "application/json",
    },
  })
}


export async function removeUploaedFile(type){
  return request({
    url: `${SCEPTER_URL}/logo-sign`,
    method: "DELETE",
    data: { type },
    headers: {
      "Content-Type": "application/json",
    },
  })
}


import {UPDATE_SUBSCRIPTION,UPDATE_SUBSCRIPTIONONLY,UPDATE_ALL_ORGANIZATION_MANAGERS} from '../constants';

const initialState = {
  userSlot: null,
  subscription: null,
  organizationSlot: null,
}

export const subscription = (state = initialState, action = {}) => {
  switch(action.type) {
    case UPDATE_SUBSCRIPTION:
      let {Subscription, organizationSlot} = action.payload;
      return { 
        ...state, 
        userSlot: action.payload,
        subscription: Subscription,
        organizationSlot
      }
    default:
      return state;
  }
}

export const subscriptionOnly=(state=initialState,action={})=>{
  switch(action.type){
    case UPDATE_SUBSCRIPTIONONLY:
      
      return {...state, ...action.payload}
    default:
      return state;
  }
}


export const allOrganizationManagers=(state=initialState,action={})=>{
  switch(action.type){
    case UPDATE_ALL_ORGANIZATION_MANAGERS:
      return {...state, ...action.payload}
     
      default:
        return state;
  }
}
import {auth} from './reducers/user.reducer';
import {organization} from './reducers/organization.reducer';
import {allCustomersOfOrg} from './reducers/customers.reducer';

import {receipts} from './reducers/receipts.reducer';
import {subscription,subscriptionOnly,allOrganizationManagers} from './reducers/subscription.reducer';
import {allOrganization} from './reducers/organization.reducer';
import { treeOrganization } from './reducers/organization.reducer';
import { monthwiseSummary,monthwiseOutstandings,monthlyRTO } from './reducers/monthwise.reducer';
  // Used in Customer Portal
import {customer} from './reducers/customerApp.reducer';
import { cfmart } from './reducers/cf-mart.reducer';
import { stockItems,stockCategories,stockGroups, stockItemsInvoicesById, stockItemsBillsById, stockItemsCustomers, stockItemsSuppliers } from './reducers/stocks.reducers';
import { allSchemes,allIncContactsWithSchemes } from './reducers/incentives.reducer';
import { allVendors } from './reducers/payScreen.reducer';
import { fetchContactDetailsData } from './reducers/organization.reducer';
import { getCollectionUi } from './reducers/collectionUi.reducer';
import { getAllTasks } from './reducers/crmScreen.reducer';
import { getDashBoardData } from './reducers/dashboard.reducer';
import { getCollectScreenData } from './reducers/collect.reducer'
import { getDebtorSummaryDetails } from './reducers/debtorSummary.reducer';
import { invoicePrefill } from './reducers/invoicePrefill.reducer';
const reducer = {
  auth,
  allVendors,
  organization,
  allCustomersOfOrg,
  receipts,
  allOrganization,
  getAllTasks,
  subscription,
  treeOrganization,
  subscriptionOnly,
  allOrganizationManagers,
  monthwiseOutstandings,
  monthlyRTO,
  monthwiseSummary,
  stockItems,
  stockGroups,
  stockCategories,
  stockItemsInvoicesById,
  stockItemsBillsById,
  fetchContactDetailsData,
  // used in customer panel
  customer,
  allSchemes,
  allIncContactsWithSchemes,
  getCollectionUi,
  cfmart,
  getDashBoardData,
  getCollectScreenData,
  getDebtorSummaryDetails,
  stockItemsCustomers,
  stockItemsSuppliers,
  invoicePrefill
}

export default reducer;
import {UPDATE_CUSTOMERS, UPDATE_DUE_AGEING_SUMMARY_FOR_CONTACT} from '../constants';

const initialState = {}

export const allCustomersOfOrg = (state = initialState, action = {}) => {
  switch(action.type) {
    case UPDATE_CUSTOMERS:
      return { ...state, allCustomersOfOrg: action.payload }
    case UPDATE_DUE_AGEING_SUMMARY_FOR_CONTACT:
      return { ...state, dueAgeingSummaryForContact: action.payload}
    default:
      return state;
  }
}
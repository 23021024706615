import {GET_CALCULATE_TOTAL_CASH_AND_BANK_BALANCE, GET_TODAYS_TASK_COUNT, GET_REMINDER_SEND_THIS_MONTH,GET_BAD_DEBTOR_COUNT, GET_CALCULATE_TOTAL_INVENTORY_VALUE, GET_CALCULATE_TOTAL_PAYABLES,GET_MONTHWISE_SALES, GET_MONTHWISE_COLLECTION, GET_MONTHWISE_OUTSTANDING, GET_MONTHWISE_OUTSTANDING_DISTRIBUTION, GET_MONTHWISE_RECOVERY_DISTRIBUTION, GET_CALCULATE_AGEING_SUMMARY_BUCKETWISE, GET_COUNT_INVOICE_DUE_TODAY, GET_TOP_CUSTOMERS, GET_MONTHWISE_PAYMENT_TREND, GET_CALCULATE_MONTHWISE_PURCHASE, GET_CALCULATE_TOTAL_OUTSTANDING, GET_PROJECTED_COLLECTION, GET_USER_DETAILS_DATA, RESET_DASHBOARD_REDUX_DATA, GET_COUNT_BILLS_DUE_TODAY, SET_ORGANIZATION_KYC_DATA, PRE_APPROVAL_PRODUCT, PRE_APPROVAL_KUBERX_ID, PRE_APPROVAL_TOKEN } from "../constants";

export const setMonthwiseSales = (monthWiseSales) => ({
    type: GET_MONTHWISE_SALES,
    payload: monthWiseSales
});

export const setMonthwiseCollections = (monthWiseCollections) => ({
    type: GET_MONTHWISE_COLLECTION,
    payload: monthWiseCollections
});

export const setMonthwiseOutStanding = (monthWiseOutStaning) => ({
    type: GET_MONTHWISE_OUTSTANDING,
    payload: monthWiseOutStaning
});

export const setMonthwiseOutStandingDistribution = (monthWiseOutStaningDistribution) => ({
    type: GET_MONTHWISE_OUTSTANDING_DISTRIBUTION,
    payload: monthWiseOutStaningDistribution
});

export const setMonthwiseRecoveryTimeDistribution = (monthWiseTimeRecoveryDistribution) => ({
    type: GET_MONTHWISE_RECOVERY_DISTRIBUTION,
    payload: monthWiseTimeRecoveryDistribution
});

export const setCalculateAgeingSummaryBucketWise = (calculateAgeingSummaryBucketWise) => ({
    type: GET_CALCULATE_AGEING_SUMMARY_BUCKETWISE,
    payload: calculateAgeingSummaryBucketWise
});

export const setCountInvoicesDueTodayData = (countInvoicesDueToday) => ({
    type: GET_COUNT_INVOICE_DUE_TODAY,
    payload: countInvoicesDueToday
});

export const setTopCustomersData = (getTopCustomers) => ({
    type: GET_TOP_CUSTOMERS,
    payload: getTopCustomers
}); 

export const setMonthwisePaymentTrend = (monthwisePaymentTrend) =>({
    type: GET_MONTHWISE_PAYMENT_TREND,
    payload: monthwisePaymentTrend
})

export const setCalculateMonthwisePurchases = (calculateMonthwisePurchases) =>({
    type: GET_CALCULATE_MONTHWISE_PURCHASE,
    payload: calculateMonthwisePurchases
})

export const setCalculateTotalOutstanding = (calculateTotalOutstanding) => ({
    type: GET_CALCULATE_TOTAL_OUTSTANDING,
    payload: calculateTotalOutstanding
});
export const setCalculateTotalPayables = (calculateTotalPayables) => ({
    type: GET_CALCULATE_TOTAL_PAYABLES,
    payload: calculateTotalPayables
});
export const setCalculateTotalInventoryValue = (calculateTotalInventoryValue) => ({
    type: GET_CALCULATE_TOTAL_INVENTORY_VALUE,
    payload: calculateTotalInventoryValue
});
export const setCalculateTotalCashAndBankBalance = (calculateTotalCashAndBankBalance) => ({
    type: GET_CALCULATE_TOTAL_CASH_AND_BANK_BALANCE,
    payload: calculateTotalCashAndBankBalance
});

export const setProjectedCollection = (projectCollection) => ({
    type: GET_PROJECTED_COLLECTION,
    payload: projectCollection
}); 

export const setRemiderSendThisMonth = (reminderSendThisMonth) => ({
    type: GET_REMINDER_SEND_THIS_MONTH,
    payload: reminderSendThisMonth
}); 

export const setBadDebtorsCount = (badDebtorsCount) => ({
    type: GET_BAD_DEBTOR_COUNT,
    payload: badDebtorsCount
}); 

export const setBillsDueTodayCount = (billsDueTodayCount) => ({
     type: GET_COUNT_BILLS_DUE_TODAY,
     payload: billsDueTodayCount,
});
export const setUserDetailsData = (userDetailsData) =>({
    type: GET_USER_DETAILS_DATA,
    payload: userDetailsData
});

export const setTodaysTaskCount = (todaysTaskCount) =>({
    type: GET_TODAYS_TASK_COUNT,
    payload: todaysTaskCount
});

export const resetDashBoardReduxData = (resetData) =>({
    type: RESET_DASHBOARD_REDUX_DATA,
    payload: resetData
});

export const setOrganizationKycData = (kycData) => ({
    type: SET_ORGANIZATION_KYC_DATA,
    payload: kycData
})

export const setPreApprovalProduct = (preApprovalProduct) => ({
    type: PRE_APPROVAL_PRODUCT,
    payload: preApprovalProduct
})

export const setPreApprovalKuberxId = (preApprovalKuberxId) => ({
    type: PRE_APPROVAL_KUBERX_ID,
    payload: preApprovalKuberxId
})

export const setPreApprovalToken = (preApprovalToken) => ({
    type: PRE_APPROVAL_TOKEN,
    payload: preApprovalToken
})
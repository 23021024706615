import {SET_CONTACT_DETAILS, SET_ALL_ORGANIZATION, UPDATE_ORGANIZATION, SET_LOKI, SET_TALLY_GROUPH_GRAPH, SET_PROCESS_EVENT_IN_MEMORY_DATA_STATUS, SET_SYNC_SOURCE_AVAILABLE_FLAG} from '../constants';
import { UPDATE_ALL_ORGANIZATION } from '../constants';
import { UPDATE_TREE_ORGANIZATION } from '../constants';

export const updateOrganization = (organization) => ({
  type: UPDATE_ORGANIZATION,
  payload: organization
});

export const updateAllOrganization=(allOrganization)=>({
  type: UPDATE_ALL_ORGANIZATION,
  payload: allOrganization
});

export const setAllOrganization=(allOrganization)=>({
  type: SET_ALL_ORGANIZATION,
  payload: allOrganization
});

export const updateTreeOrg=(treeOrg)=>({
  type:UPDATE_TREE_ORGANIZATION,
  payload:treeOrg
});

export const setContDetailsData = (data) => ({
  type: SET_CONTACT_DETAILS,
  payload: data
});

export const setLOKI = (data) => ({
  type: SET_LOKI,
  payload: data
});

export const setTallyGroupGraphForThisOrg = (tallyGroupGraph) => ({
  type: SET_TALLY_GROUPH_GRAPH,
  payload: tallyGroupGraph
});

export const ProcessEventInMemoryDataStatus = (inMemoryDataStatus) => ({
  type: SET_PROCESS_EVENT_IN_MEMORY_DATA_STATUS,
  payload: inMemoryDataStatus
});

export const setSyncSourceFlag = (syncSourceFlag) => ({
  type: SET_SYNC_SOURCE_AVAILABLE_FLAG,
  payload: syncSourceFlag
});
import { GET_COLLECTION_BILL_STATEMENT, GET_COLLECTION_TRANSACTIONS, GET_CREATED_EMI_SUMMERY, GET_COLLECTION_BILL_STATEMENT_ENABLED, GET_COLLECTION_START_DATE_END_DATE } from '../constants';

const initialState = {
}

export const getCollectionUi = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_COLLECTION_BILL_STATEMENT:
            return { ...state, hyperFaceBillStatement: action.payload }
        case GET_COLLECTION_TRANSACTIONS:
            return { ...state, hyperFaceTransactions: action.payload }
        case GET_CREATED_EMI_SUMMERY: 
            return { ...state, hyperFaceCreatedEmiSummery: action.payload}
        case GET_COLLECTION_BILL_STATEMENT_ENABLED: 
            return { ...state, isCollectionEnables: action.payload}
        case GET_COLLECTION_START_DATE_END_DATE:
            return { ...state, collectionStartDateEndDate: action.payload}
        default:
            return state;
    }
}
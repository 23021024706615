import CleverTap from './services/clevertapService';

const clevertapAccountId = '8RK-Z77-RZ6Z';


function initializeServices() { 
  CleverTap.initialize(clevertapAccountId);
}


export { initializeServices };

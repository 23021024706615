import { LIST_ALL_VENDOR_PAYMENT_DONE_TALLY, UPDATE_ALL_VENDORS } from "../constants";

const initialState={

}
export const allVendors=(state=initialState,action = {}) => {
    switch(action.type) {
        case UPDATE_ALL_VENDORS:
          return { ...state, ...action.payload}
        case LIST_ALL_VENDOR_PAYMENT_DONE_TALLY:
          return {...state, listAllVendorPaymnetDoneTally: action.payload}
        default:
          return state;
      }
}
import { UPDATE_ALL_SCHEMES,UPDATE_ALL_CONTACTS_WITH_SCHEMES } from "../constants";
const initialState={

}
export const allSchemes  = (state = initialState, action = {}) => {
    switch(action.type) {
      case UPDATE_ALL_SCHEMES:
        return { ...state, ...action.payload}
      default:
        return state;
    }
  }

  export const allIncContactsWithSchemes  = (state = initialState, action = {}) => {
    switch(action.type) {
      case UPDATE_ALL_CONTACTS_WITH_SCHEMES:
        return { ...state, ...action.payload}
      default:
        return state;
    }
  }
import {UPDATE_AUTH, SET_ALL_USERS, SET_ORGANIZATION_AND_SETTINGS_DETAILS} from '../constants';

export const updateAuth = (user) => ({
  type: UPDATE_AUTH,
  payload: user
});

export const setAllUsers = (user) => ({
  type: SET_ALL_USERS,
  payload: user
});

export const setOrganizationAndSettingsDetails = (organizationAndSettingsDetails) => ({
  type: SET_ORGANIZATION_AND_SETTINGS_DETAILS,
  payload: organizationAndSettingsDetails
});
import {
    SET_INVOICE_PREFILL_WHATSAPP,
    SET_INVOICE_PREFILL_MAIL,
    SET_INVOICE_PREFILL_SMS,
    SET_LEDGER_CONTENT_SMS,
    SET_LEDGER_CONTENT_WHATSAPP,
  } from "../constants";
  
  const initialState = {
    invoicePrefillWhatsApp: "",
    invoicePrefillMail: "",
    invoicePrefillSms: "",
    ledgerContentSms: "",
    ledgerContentWhatsApp: "",
  };
  
  export const invoicePrefill = (state = initialState, action = {}) => {
    switch (action.type) {
      case SET_INVOICE_PREFILL_WHATSAPP:
        return { ...state, invoicePrefillWhatsApp: action.payload };
      case SET_INVOICE_PREFILL_MAIL:
        return { ...state, invoicePrefillMail: action.payload };
      case SET_INVOICE_PREFILL_SMS:
        return { ...state, invoicePrefillSms: action.payload };
      case SET_LEDGER_CONTENT_SMS:
        return { ...state, ledgerContentSms: action.payload };
      case SET_LEDGER_CONTENT_WHATSAPP:
        return { ...state, ledgerContentWhatsApp: action.payload };
      default:
        return state;
    }
  };
  
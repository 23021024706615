import amplitude from 'amplitude-js';
import { AMPLITUDE_API_KEY } from '../helpers/utils';
import { store } from '../store';

// config
function initiateAmplitude() {
    amplitude.getInstance().init(AMPLITUDE_API_KEY);
}

// functions
export function amplitudeUserEvent() {
    const userDetailsData = store.getState().auth?.user;
    const userData = {
        name: userDetailsData.user.name,
        email: userDetailsData.user.email,
        phone: userDetailsData.user.phone,
    };
    try {
        initiateAmplitude();
        amplitude.getInstance().setUserProperties(userData);
    } catch (err) { }
}
export function amplitudeLogEvent(eventName, eventData = {}) {
    try {
        initiateAmplitude();
        amplitude.getInstance().logEvent(eventName, eventData);
    } catch (err) { }
}
import React from 'react';
import credflowLogo from '../icon/credflow-logo.svg';
import '../styles/credflow-loader.css';

function Loading() {
  return (
    <div>
      <div 
        id="credflow-loader"
      >
        <div>
          <img src={credflowLogo} alt="logo" className="loader-logo" />
        </div>
        <div className="loader-tag-line">
          Making SMEs Cash Rich.
          </div>
      </div>
    </div>
  )
}

export default Loading;
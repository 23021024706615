import { UPDATE_STOCK_CATEGORIES,UPDATE_STOCK_GROUPS,UPDATE_STOCK_ITEMS, UPDATE_STOCK_ITEMS_INVOICES_BY_ID, UPDATE_STOCK_ITEMS_BILLS_BY_ID, UPDATE_STOCK_ITEMS_CUSTOMERS, UPDATE_STOCK_ITEMS_SUPPLIERS } from "../constants";

export const updateStockCategories = (stockCategories) => ({
    type: UPDATE_STOCK_CATEGORIES,
    payload: stockCategories
  });
  
  export const updateStockItems=(stockItems)=>({
    type:UPDATE_STOCK_ITEMS,
    payload:stockItems
  })

  export const updateStockGroups=(stockGroups)=>({
    type:UPDATE_STOCK_GROUPS,
    payload:stockGroups
  })

  export const updateStockItemsInvoicesById=(stockItemsById)=>({
   type: UPDATE_STOCK_ITEMS_INVOICES_BY_ID,
   payload: stockItemsById
  })

  export const updateStockItemsBillsById = (stockItemsBillsById) => ({
    type: UPDATE_STOCK_ITEMS_BILLS_BY_ID,
    payload: stockItemsBillsById
  })

  export const updateStockItemCustomers=(stockItemsCustomers)=>({
    type: UPDATE_STOCK_ITEMS_CUSTOMERS,
    payload: stockItemsCustomers
  })

  export const updateStockItemSuppliers=(stockItemsSuppliers)=>({
    type: UPDATE_STOCK_ITEMS_SUPPLIERS,
    payload: stockItemsSuppliers
  })
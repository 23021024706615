import React from "react";
import Axios from "axios";
import Cookies from "universal-cookie";
import debounce from "lodash/debounce";
import { message, notification } from "antd";
import { getAllOrganization, getNewAccessToken, logoutUser } from "../api/user";
import { checkUserSubscription } from "../api/subscription";
import { createHash } from 'crypto';
import "./utils.css"
import  notificationCrossIcon  from "../icon/notification-blue-close-icon.svg";
import demoDataLock from "../icon/demo-data-lock.svg"
import { createLsqLead, updateConsentData } from "../api/los";
import moment from "moment";
import QRCode from "qrcode";
import analytics from "../services/analyticsService";

const query = new URLSearchParams(location.search);
const preApprovalProduct = query.get("product");
export const APP_URL = `${process.env.APP_URL}`;
export const API_URL = `${process.env.BASE_URL}/api`;
export const DARKHOLD_URL = `${process.env.BASE_URL}/darkhold`
export const BLACK_PANTHER_API_URL = `${process.env.BASE_URL}/black-panther`;
export const ULTRON_API_URL = `${process.env.BASE_URL}/ultron`;
export const ODIN_API_URL = `${process.env.BASE_URL}/odin`;
export const MJOLNIR_API_URL = `${process.env.BASE_URL}/mjolnir`;
export const QUICK_SILVER_API_URL = `${process.env.BASE_URL}/quick-silver`;
export const WANDA_API_URL = `${process.env.BASE_URL}/wanda`;
export const Stocks_API_URL = `${process.env.BASE_URL}/stocks`;
export const AUTH_API_URL = `${process.env.BASE_URL}`;
export const ACEndPoint = `${process.env.BASE_URL}/account-management`;
export const StarkEndPoint = `${process.env.BASE_URL}/stark`;
export const UDHAAR_API_URL = `${process.env.BASE_URL}/udhar`;
export const AlphaEndPoint = `${process.env.BASE_URL}/alpha`;
export const CARNAGE_API_URL = `${process.env.BASE_URL}/carnage`;
export const HisabKitabEndPoint = `${process.env.BASE_URL}/hisab-kitab`;
export const MORDO_API_URL = `${process.env.CFMART_API_BASE_URL || 'https://api.cfstore.in'}/mordo`;
export const CF_MART_DOMAIN = `${process.env.CF_MART_DOMAIN || 'cfstore.in'}`;
export const razorpayKey = process.env.RAZORPAY_KEY;
export const vendorPayOfferStatus = process.env.VENDOR_SIDE_OFFER_STATUS;
export const PUPPETTER_API_URL = `${process.env.BASE_URL}/buttler/api/puppeteer`;
export const SCEPTER_URL = `${process.env.BASE_URL}/scepter`;
export const KUBER_API_URL = `${process.env.BASE_URL}/kuber`;
export const BIFROST_API_URL = `${process.env.BASE_URL}/bifrost`;
export const PIKACHU_API_URL = `${process.env.BASE_URL}/pikachu`;
export const MYSTERIO_URL = `${process.env.BASE_URL}/mysterio`;
export const MANTIS_URL = `${process.env.BASE_URL}/mantis`;

export const WORKFLOW_ID = process.env.KYC_WORKFLOW_ID;
export const IF_LOS_KYC_WORKFLOW_ID = process.env.IF_LOS_KYC_WORKFLOW_ID || '65cdb3accd997eb1e3053f40';
export const SWORDSMAN_BASE_URL = process.env.SWORDSMAN_URL;
export const VALKYRIE_BASE_URL = process.env.VALKYRIE_URL;
export const KUBERX_BASE_URL = process.env.KUBERX_BASE_URL;
export const MIN_KYC_WORKFLOW_ID = process.env.MIN_KYC_WORKFLOW_ID || '6582b9ebf37af09052dfbf72';
export const AMPLITUDE_API_KEY = preApprovalProduct === "KUBERX_TAB" ? "748cce435bb861e6e9273ca6b0c74f1b" : '34b4f823f8f812085665ee63ea397662';
export const KUBERX_FRONTEND_URL = `${process.env.KUBERX_FRONTEND_URL}`

const cookies = new Cookies();
const axios = Axios.create({});

// Axios Interceptors Configuration
axios.interceptors.request.use(
  config =>
  { 
    const token = getAccessToken();
    const organizationId = getOrganizationId();
    const rmToken = getRmToken();
    const xAuthToken = new URLSearchParams(window.location.search).get('token');
    const globalPaymentId = new URLSearchParams(window.location.search).get('global-pay');

    if(rmToken) {
      config.headers['rm-authorization'] = rmToken;
    }

		if (token) {
      config.headers['Authorization'] = token;
    }

    if(globalPaymentId) {
      if(window.location.href.includes("/customer")) {
        config.headers['global-pay'] = globalPaymentId;
      }
    }

		if (!globalPaymentId && xAuthToken) {
      if(window.location.href.includes("/customer")) {
        config.headers['x-auth-token'] = xAuthToken;
      }
    }
    
    if (organizationId)
      config.headers.common["organizationId"] = organizationId;
      config.headers.common["platform"] = "Web";
    // config.headers.common["access"] =  getAccessToken();


		return config;
	},
	error => {
		Promise.reject(error);
	}
);

let isOpened = false
let openDemoVideo = false
const openNotification = () => {
  if (isOpened) {
    return
  }
  const args = {
    message:
      <div className="demoDataMessageContainer">
        <img src={demoDataLock} className="demoDataImageLock" />
        <div>This Feature not applied on demo account.
          If you have to unlock this feature sync your tally,
          <br />
          <p className="demoDataVideoLink" onClick={openDemoDataVideo}>
            How To Sync
          </p>
        </div>
      </div>,
    duration: 0,
    placement: 'top-center',
    onClose: () => {
      isOpened = false
    },
    closeIcon: <img src={notificationCrossIcon} alt="ntf close" style={{ marginTop: "5px" }} />
  };
  notification.open(args);
  isOpened = true
};

const openDemoDataVideo = () => {
  if (openDemoVideo) {
    return
  }
  const args = {
    message:
      <>
        <iframe 
          width="650" 
          height="450" 
          src="https://www.youtube.com/embed/wQSBaLUHmq4" 
          title="How to sync your Tally with CREDFLOW || CredFlow" 
          frameborder="0"
        />
      </>,
    duration: 0,
    placement: 'center-center',
    onClose: () => {
      openDemoVideo = false
    },
    closeIcon: <img src={notificationCrossIcon} alt="ntf close" style={{ marginTop: "5px" }} />
  };
  notification.open(args);
  openDemoVideo=true
}

//Add a response interceptor
var cancelRequests = [];
var failedRequests = [];
axios.interceptors.response.use(
	response => {
		return response;
	},
	function (error) {
    if (error.response.status === 432) {
      openNotification()
      return
    }
    const originalRequest = error.config;
    const checkIfAgainHitting = failedRequests.some(_req => _req === originalRequest.url);
    
    if (error.response.status === 401 && (originalRequest.url.includes('/login') || originalRequest.url.includes('renew-token') || originalRequest.url.includes('logout') || checkIfAgainHitting))
    {
      failedRequests=[]; 
      return Promise.reject(error);
    }
    else if (error.response.status === 401)
    {
      const cancelToken = Axios.CancelToken.source();
      cancelRequests.push(cancelToken);
      failedRequests.push(originalRequest.url);

      const rmToken = getRmToken();
      if(!rmToken) {
			return getNewAccessToken(cancelToken.token)
				.then(response => {
          const { authorization } = response.headers;
          setAccessToken(authorization);
          //cancelling all the previous renew-token requests
          for (let i = cancelRequests.length - 1; i >= 0; i--)
          {
            cancelRequests[i].cancel();
            cancelRequests.pop();
          }
          //Executing request with New Access Token
          return axios(originalRequest);
          
        }).catch((_err) =>{
          //Executing cancelled request with New Access Token
          if (Axios.isCancel(_err))
            return axios(originalRequest);
          else{
            logoutUser()
              .then(() =>{
                // elasticApm.setCustomContext({
                //   organizationId : undefined,
                //   organizationName : undefined
                // });
                // elasticApm.setUserContext({
                //   id : undefined,
                //   username: undefined,
                //   email : undefined
                // });
                removeUserCredentials({ showMessage: true });
                message.info("Session Expired!");
                window.location.replace('/login');
                analytics.event('Logout',{}, ['ga', 'amp']);
              })
              .catch(() =>{
                removeUserCredentials({showMessage: true});
                message.info("Session Expired!");
                window.location.replace('/login');
              });
          }
        });
      }
		}

		// return Error object with Promise
		return Promise.reject(error);
	}
);

export const userAccessToken = cookies.get("token");
export const customerToken = window.sessionStorage.getItem("customer-token");

if (userAccessToken) setRequestAuthHeader(userAccessToken);

export const userERP = "tally";

export function isAuthenticated()
{
  const query = new URLSearchParams(window.location.search);
  let token = query.get("token") || window.localStorage.getItem("accessToken");
  let orgId = query.get("orgId") || window.localStorage.getItem("organizationId");
  let rmToken = query.get("rm_token") || window.localStorage.getItem("rmToken");
  let identifier = query.get("identifier") || window.localStorage.getItem("identifier");
  let product = query.get("product") || window.localStorage.getItem("product");
  let workflowId = query.get("workflowid") || window.localStorage.getItem("workflowId");
  let refreshToken = query.get("refreshToken");
  if(token && orgId) {
    window.localStorage.setItem("accessToken", token);
    window.localStorage.setItem("organizationId", orgId);
    if(refreshToken) {
      window.localStorage.setItem("refreshToken", refreshToken);
    }
    return true;
  }
  if(rmToken && orgId) {
    window.localStorage.setItem("rmToken", rmToken);
    window.localStorage.setItem("organizationId", orgId);
    if(identifier)
    window.localStorage.setItem("identifier", identifier);
    if(product)
      window.localStorage.setItem("product", product);
    if(workflowId)
      window.localStorage.setItem("workflowId", workflowId);
    
    return true;
  }
  return !!getAccessToken();
}

// Authority functions

export function setAuthority(authority){
  window.localStorage.setItem('authority',JSON.stringify(authority));
}

export const getAuthority=()=>
{
  const authorityList = localStorage.getItem('authority');
  if (!!authorityList) return JSON.parse( authorityList);
  else return [];
}

// authorities.includes(key);
export const checkAuthority=(_authorities)=>{
  const authoritiesList = getAuthority();
  if (!Array.isArray(authoritiesList)) return false;
  //checking if for any case input is string instead of array of string
  if (!Array.isArray(_authorities)) _authorities=[_authorities];
  const authoritiesSet = new Set(authoritiesList);
  const hasAccessList = _authorities?.map((_auth) => authoritiesSet.has(_auth));
  return hasAccessList.includes(true);
}


// Access Token
export function setAccessToken(accessToken) {
  localStorage.setItem('accessToken', accessToken);
}
export function getAccessToken(){
  return localStorage.getItem('accessToken');
}

// Refersh Token
export function setRefreshToken(refreshToken) {
  window.localStorage.setItem('refreshToken', refreshToken);
}

export function getRefreshToken()
{
  return window.localStorage.getItem('refreshToken');
}

export function setOrganizationId(organizationId)
{
  window.localStorage.setItem('organizationId', organizationId);
}

export function getOrganizationId(){
  return window.localStorage.getItem('organizationId');
}

export function getRmToken(){
  return window.localStorage.getItem('rmToken');
}

export function removeUserCredentials({showMessage=false}={}){
  if (showMessage){
    message.info("Something went wrong! Please try login Again!")
  }
  localStorage.removeItem("accessToken", { path: "/" });
  localStorage.removeItem("refreshToken", { path: "/" });
  localStorage.removeItem("authority", { path: "/" });
  localStorage.removeItem("organizationId", { path: "/" });
  localStorage.removeItem("rmToken", { path: "/" });
  localStorage.removeItem("organizationAccess", { path: "/" });
  localStorage.removeItem("isRequestingNewToken", { path: "/" });
  localStorage.removeItem("tallySyncBanner", { path: "/" });
  localStorage.removeItem("newCompanyPopupLastShown", { path: "/" });

  cookies.remove("x-auth-token", { path: "/" });
  cookies.remove("erp", { path: "/" });
  cookies.remove("organizationId", { path: "/" });
  cookies.remove("rmToken", { path: "/" });
  cookies.remove("access", { path: "/" });
  cookies.remove("haveCompany", { path: "/" });
}

export function getErpCookie() {
  return cookies.get("erp");
}

// Orgnization's Subscription Status
export const setOrganizationAccess = (_organizationAccess) =>{
  window.localStorage.setItem('organizationAccess', _organizationAccess)
};
export const getOrganizationAccess = () => window.localStorage.getItem('organizationAccess');

export const setUserAccess = (_userAccess) =>{
  window.localStorage.setItem('userAccess', _userAccess)
};
export const getUserAccess = () => window.localStorage.getItem('userAccess');

export function request(config) {
  return axios.request(config);
}

export const checkUserAndgetAllOrganization = async () =>{
  try{
    const userResponse = await checkUserSubscription();
    if (userResponse){
      const userAccess = userResponse.data.subscription;
      setUserAccess(userAccess);
      if (userAccess === "complete"){
        return await getAllOrganization();
      } else{
        return { data: [] };
      }
    }
    
  } catch (error) {
    handleCatch(error);
  }
};



export function reduceData(data, key) {
  return data.reduce(function (acc, data) {
    return (acc = Number(acc) + Number(data[key]));
  }, 0);
}

export const numberFlooredToStep = (number, step) => {
  const result = Math.floor((number)/step)*step;
  return result;
}

export const addCommas = (x) => {
  if (!x) {
    return x;
  }
  let isNegative = false;
  if (+x < 0) {
    isNegative = true;
    x = Math.abs(+x);
  }
  x = x.toString();
  let dot = x.indexOf(".");
  if (dot !== -1) {
    var dec = x.slice(dot, x.length);
    x = x.slice(0, dot);
  }
  var lastThree = x.substring(x.length, x.length - 3);
  var otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers != "") lastThree = "," + lastThree;
  var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  if (dec) {
    res = res + dec;
  }

  if (isNegative) {
    return `-${res}`;
  } else {
    return res;
  }
};

export const removeCommas = (x) => {
  if (!x) return x;

  x = x.toString();
  let dot = x.indexOf(".");
  if (dot !== -1) {
    var dec = x.slice(dot, x.length);
    x = x.slice(0, dot);
  }
  for (let i = 0; i < x.length; i++) {
    if (x.charAt(i) === ",") {
      x = x.slice(0, i) + x.slice(i + 1);
    }
  }
  if (dec) {
    x = x + dec;
  }
  return x;
}

export const removeDuplicates = (arr) => {
  if (arr.length < 1) {
    return arr;
  }
  let new_arr = arr.filter(function (value, index, array) {
    return array.indexOf(value) == index;
  });
  return new_arr;
};

export const validateEmail = (email) => {
  if (!email) {
    return false;
  }
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const getIndicesOf = (searchStr, str, caseSensitive) => {
  var searchStrLen = searchStr.length;
  if (searchStrLen == 0) {
    return [];
  }
  var startIndex = 0,
    index,
    indices = [];
  if (!caseSensitive) {
    str = str.toLowerCase();
    searchStr = searchStr.toLowerCase();
  }
  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }
  return indices;
};

export const buildTable = (data) => {
  let tableStart = `<table style="font-size: 15px; font-family: arial, sans-serif;border-collapse: collapse;width: 100%;">`;
  let tableEnd = `</table>`;
  let htmlCont = ``;
  let tableHeaders = Object.keys(data[0]);
  let tableHeaderHTML = ``;
  let tableBodyHTML = ``;
  data.forEach((element, i) => {
    tableBodyHTML += `<tr>`;
    tableHeaders.forEach((el) => {
      if (i == 0)
        tableHeaderHTML +=
          `<th style="border: 1px solid #dddddd;text-align: left;padding: 8px;">` +
          el +
          `</th>`;
      tableBodyHTML +=
        `<td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">` +
        (element[el] || "") +
        `</td>`;
    });
    tableBodyHTML += `</tr>`;
  });
  let tableHead = `<thead><tr>` + tableHeaderHTML + `</tr></thead>`;
  let tableBody = `<tbody>` + tableBodyHTML + `</tbody>`;
  htmlCont = tableStart + tableHead + tableBody + tableEnd;
  return htmlCont;
};

export const handleCatch = (err) =>{
  if (err.response && err.response.data === "Access denied. No token provided")
    return;
  if (typeof err === "string" && err instanceof String) {
    message.error(err);
  }
  if (typeof err === "string") {
    message.error(err);
  }
  else if (err?.response?.status === 403)
  {
    return;
  }
  else if (
    typeof err.response === "string" &&
    err.response instanceof String
  ) {
    message.error(err.response);
  } else if (err.response && err.response.data && err.response.data.error) {
    message.error(err.response.data.error);
  } else if (err.response && err.response.data) {
    message.error(err.response.data);
  } else if (err?.error?.description) {
    message.error(err?.error?.description);
  } else {
    message.error("Some error has occured please refresh...");
  }
};

export const handleLosError = (error) => {
  if (error && error.message) {
    message.error(error?.message);
  } else if (error?.response?.data?.isUserError || error?.response?.data?.isSystemError || error?.response?.data?.message) {
    message.error(error?.response?.data?.message);
  } else if (error?.response?.data?.errorMessage) {
    message.error(error?.response?.data?.errorMessage);
  } else {
    handleCatch(error);
  }
}

export const getPagefromCookies = () => {
  const page = cookies.get("page");
  return page;
};

let timerId;
export const throttleFunction = (callback, delay) => {
  if (timerId) {
    return;
  }
  timerId = setTimeout(function () {
    callback();
    timerId = undefined;
  }, delay);
};

export function openNewTab(url) {
  window.open(url);
}

export function handleUnpaidSlot(subscription) {
  switch (subscription.name) {
    case "Super Saver":
      openNewTab("https://rzp.io/l/supersaver-extracredits");
      break;
    case "Saver":
      openNewTab("https://rzp.io/l/saver-extra-units");
      break;
    case "Lite Plus":
      openNewTab("https://rzp.io/l/credflow-lite-plus");
      break;
    case "Lite":
      openNewTab("https://rzp.io/l/credflow-lite");
      break;
    default:
      openNewTab("https://rzp.io/l/credflow-lite");
      break;
  }
}

export const takeSubscriptionAction = (
  tagUserOrganization,
  subscription,
  organizationSlot,
  userSlot
) => {
  function showBanner() {
    window.location.replace('https://credflow.in/#pricing2');//document.getElementById('pricing-container').classList.remove('d-none');
  }
  try {
    let access = getOrganizationAccess();
    if (access === "complete") return;

    if (tagUserOrganization && tagUserOrganization.isPrimaryAdmin === false)
      return;

    if (subscription.state === "demo") {
      return;
    } else if (subscription.state === "trial") {
      return;
    } else if (subscription.state === "paid") {
      if (organizationSlot.isBasicSlot || organizationSlot.access === "paid") {
        // 'paid' organization slot
        // 'complete' for 'paid' users, 'none' for others
        if (userSlot.isBasicSlot || userSlot.access === "paid") {
          // complete
          return;
        } else {
          // user slot is additional slot and not paid
          handleUnpaidSlot(subscription);
          return;
        }
      } else {
        // organization is neither in basic slot nor paid
        // send to payment page for add-on slots
        handleUnpaidSlot(subscription);
        return;
      }
    } else if (subscription.state === "free") {
      showBanner();
      return;
    }
    showBanner();
    return;
  } catch (ex) {
    console.log(ex);
    showBanner();
  }
};

export const debouncedSearch = (func, d) => {
  let timer;
  return function () {
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(args);
    }, d)
  }
}

export const debouncedSearchControlled = debounce((callback) =>{
  callback();
},1000);

export const getWidthString = span => {
	if (!span) return;

	let width = (span / 24) * 100;
	return `width: ${width}%;`;
};

export const onlyUnique = a => {
	let seen = {};
  let out = [];
  let len = a.length;
  let j = 0;
  for(let i = 0; i < len; i++) {
    let item = a[i];
       if(seen[item] !== 1) {
             seen[item] = 1;
             out[j++] = item;
       }
  }
  return out;
};

export const checkForContactDetails = (data, type) => {
  if (data.length === 0) {
    return null;
  } else {
    switch (type) {
      case 'mail':
        return data.filter(entry => entry?.contactEmail && entry?.contactEmail !== '');
      case 'sms':
      case 'phone':
        return data.filter(entry => entry?.contactPhone && entry?.contactPhone !== '91null' && entry?.contactPhone !== '');
      case 'whatsapp':
        return data.filter(entry => entry?.contactWhatsapp && entry?.contactWhatsapp !== '91null' && entry?.contactWhatsapp !== '');
      default:
        return null;
    }
  }
}

export const covertToKebabCase = (input) => {
  return input && input.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
                       .map(char => char.toLowerCase())
                       .join('-');
};

export const convertToCamelCase = (input) => {
  return input && input.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '').replace(/-/g, '');
}

export function generateHash(valueToHash) {
  const hashObject = createHash('sha512');
  const genHash = hashObject.update(valueToHash, 'utf-8').digest('base64');
  return genHash;
}

export function isInViewport(el) {
  const rect = el.getBoundingClientRect();
  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export const getDifferenceFromTwoArray = (array1, array2) => {
  return array1.filter(object1 => {
      return !array2.some(object2 => {
          return object1.id === object2.id;
      });
  });
}

export const getBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const consentApiCall = async (gstNo, thirdPartyConsent = false) => {
  // if (!navigator.geolocation) {
  //     console.log("Geolocation is not supported by this browser.");
  //     return false;
  // }
  try {
      // const position = await new Promise((resolve, reject) => {
      //     navigator.geolocation.getCurrentPosition(resolve, reject);
      // });

      // const { latitude, longitude } = position.coords;
      // const userAgent = navigator.userAgent;
      const res = await updateConsentData(gstNo, {
          location: {
              lat: "-",
              long: "-"
          },
          userAgent: "-",
          isThirdPartyConsent: thirdPartyConsent
      });
      if (res.status === 200) {
          return true;
      }
  } catch (error) {
      if (error.response.data?.errorMessage) message.error(error.response.data.errorMessage);
      if (error.response.data) message.error(error.response.data);
      else message.error('Some error occurred, Please try again');
      return false;
  }
}

export function createCommunicationPayload() {
  const payload = {
      dHashArr: [],
      startDate:moment().subtract(2, 'months').format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD")
  }
  const thisMonth = moment().format("MM-YYYY"),
  previousMonth = moment().subtract(1, 'month').format("MM-YYYY"),
  previousToPrevious = moment().subtract(2, 'months').format("MM-YYYY");
  
  [thisMonth, previousMonth, previousToPrevious].forEach(monthKey => {
      payload.dHashArr.push({
          key: monthKey,
          value: localStorage.getItem(`COMMUNICATION_FILE_HASH_${getOrganizationId()}_${monthKey}`)
      });
  })
  return payload;
}
export function bytesToMB(bytes) {
  return bytes / (1024 * 1024);
}

export function getContactDetailsHelperFunction(ledgerDetail, contactId, response) {
  let madeUpCredflowDetails = [];
  if (response[0].contactDetails && response[0].contactDetails instanceof Array) {
    response[0].contactDetails
      .filter((cd) => cd.addedThrough == "tally")
      .forEach((cd) => {
        madeUpCredflowDetails.push({
          addedThrough: "tally",
          automatedReminder: ledgerDetail?.automatedReminder,
          automatedReminderFreq: ledgerDetail?.automatedReminderFreq,
          contactEmail: cd.contactEmail,
          contactId: contactId,
          contactPhone: cd.contactPhone,
          contactWhatsapp: cd.contactWhatsapp,
          datewiseReminderDates: ledgerDetail?.datewiseReminderDates,
          isDatewiseReminder: ledgerDetail?.isDatewiseReminder,
          isReminderEmail: ledgerDetail?.isReminderEmail,
          isReminderIVR: ledgerDetail?.isReminderIVR,
          isReminderSMS: ledgerDetail?.isReminderSMS,
          primaryCommMode: boolsToCommMode(ledgerDetail?.isReminderEmail, ledgerDetail?.isReminderSMS, ledgerDetail?.isReminderIVR)
        });
      });
    const commDetails = getLedgerCommDetails(undefined, ledgerDetail?.contactDetails);
    commDetails.ledgerCommDetailsFromCredflow.forEach((commDetail) => {
      madeUpCredflowDetails.push({
        addedThrough: "frontend",
        automatedReminder: ledgerDetail?.automatedReminder,
        automatedReminderFreq: ledgerDetail?.automatedReminderFreq,
        contactEmail: commDetail.email,
        contactId: contactId,
        contactPhone: commDetail.phoneNumber,
        contactWhatsapp: commDetail.whatsApp,
        datewiseReminderDates: ledgerDetail?.datewiseReminderDates,
        isDatewiseReminder: ledgerDetail?.isDatewiseReminder,
        isReminderEmail: ledgerDetail?.isReminderEmail,
        isReminderIVR: ledgerDetail?.isReminderIVR,
        isReminderSMS: ledgerDetail?.isReminderSMS,
        primaryCommMode: boolsToCommMode(ledgerDetail?.isReminderEmail, ledgerDetail?.isReminderSMS, ledgerDetail?.isReminderIVR)
      });
    });
  } else {
    const commDetails = getLedgerCommDetails(response[0].contactDetails, ledgerDetail?.contactDetails);
    commDetails.ledgerCommDetailsFromTally.forEach((commDetail) => {
      madeUpCredflowDetails.push({
        addedThrough: "tally",
        automatedReminder: ledgerDetail?.automatedReminder,
        automatedReminderFreq: ledgerDetail?.automatedReminderFreq,
        contactEmail: commDetail.email,
        contactId: contactId,
        contactPhone: commDetail.phoneNumber,
        contactWhatsapp: commDetail.whatsApp,
        datewiseReminderDates: ledgerDetail?.datewiseReminderDates,
        isDatewiseReminder: ledgerDetail?.isDatewiseReminder,
        isReminderEmail: ledgerDetail?.isReminderEmail,
        isReminderIVR: ledgerDetail?.isReminderIVR,
        isReminderSMS: ledgerDetail?.isReminderSMS,
        primaryCommMode: boolsToCommMode(ledgerDetail?.isReminderEmail, ledgerDetail?.isReminderSMS, ledgerDetail?.isReminderIVR)
      });
    });
    commDetails.ledgerCommDetailsFromCredflow.forEach((commDetail) => {
      madeUpCredflowDetails.push({
        addedThrough: "frontend",
        automatedReminder: ledgerDetail?.automatedReminder,
        automatedReminderFreq: ledgerDetail?.automatedReminderFreq,
        contactEmail: commDetail.email,
        contactId: contactId,
        contactPhone: commDetail.phoneNumber,
        contactWhatsapp: commDetail.whatsApp,
        datewiseReminderDates: ledgerDetail?.datewiseReminderDates,
        isDatewiseReminder: ledgerDetail?.isDatewiseReminder,
        isReminderEmail: ledgerDetail?.isReminderEmail,
        isReminderIVR: ledgerDetail?.isReminderIVR,
        isReminderSMS: ledgerDetail?.isReminderSMS,
        primaryCommMode: boolsToCommMode(ledgerDetail?.isReminderEmail, ledgerDetail?.isReminderSMS, ledgerDetail?.isReminderIVR)
      });
    });
  }

  return madeUpCredflowDetails;
}

export const boolsToCommMode = (isReminderEmail, isReminderSMS, isReminderIVR) => {
  if (isReminderEmail && isReminderSMS) {
      return "email and sms";
  } else if (isReminderEmail && isReminderIVR) {
      return "email and ivr";
  } else if (isReminderEmail) {
      return "email";
  } else if (isReminderSMS) {
      return "sms";
  } else if (isReminderIVR) {
      return "ivr";
  } else return "";
};

function getLedgerCommDetails(fromTally, fromCredflow) {
  const ledgerCommDetailsFromTally = [];
  const ledgerCommDetailsFromCredflow = [];
  if (fromTally) {
      for (let i = 0; i < Math.max(fromTally.email?.length, fromTally.phone?.length, fromTally.whatsapp?.length); i++) {
          ledgerCommDetailsFromTally.push({
              email: fromTally.email ? fromTally.email[i] : "",
              phoneNumber: fromTally.phone ? fromTally.phone[i] : "",
              whatsApp: fromTally.whatsapp ? fromTally.whatsapp[i] : ""
          });
      }
  }
  if (fromCredflow) {
      for (let i = 0; i < Math.max(fromCredflow.email?.length, fromCredflow.phone?.length, fromCredflow.whatsapp?.length); i++) {
          ledgerCommDetailsFromCredflow.push({
              email: fromCredflow.email ? fromCredflow.email[i] : "",
              phoneNumber: fromCredflow.phone ? fromCredflow.phone[i] : "",
              whatsApp: fromCredflow.whatsapp ? fromCredflow.whatsapp[i] : ""
          });
      }
  }
  return { ledgerCommDetailsFromTally, ledgerCommDetailsFromCredflow };
}

const eventListeners = {};

export function addCustomEventListener(event, callback) {
  if (!eventListeners[event]) {
    eventListeners[event] = callback;
  }
}

export function removeCustomEventListener(event) {
  if (eventListeners[event]) {
    delete eventListeners[event];
  }
}

export function emitCustomEvent(event, data) {
  if (eventListeners[event]) {
     eventListeners[event](data);
  }
}

/**
 * Used to call broacast directly using .then .catch or async/await.
 * Put await in front in case of same method call multiple times. 
 */
export async function callBroadCastAsynchronously(eventName, args) {
  return new Promise((resolve, reject) => {
    addCustomEventListener(eventName, (event) => {
      if (event.data.statusCode === 200) {
        resolve(event.data.response);
      } else {
        reject(new Error('Error fetching data of Method ', eventName));
      }
      removeCustomEventListener(eventName);
    });
    broadcast.postMessage({
      method: eventName,
      arguments: args,
      tabId: sessionStorage.tabID
    });
  });
}

export const getQRCodeStringToBase64 = async (url, callback) => {
  let qrCode = "";
  try {
    qrCode = await QRCode.toDataURL(url);
  } catch (error) {
    console.error(error);
  }
  if (callback) {
    callback(qrCode);
  } else {
    return qrCode;
  }
}

export function maskNumber(number, maskCharacter = "•", visibleDigits = 4) {
  const numberStr = String(number);
  const maskedPart = numberStr.slice(0, -visibleDigits).replace(/\d/g, maskCharacter);
  const visiblePart = numberStr.slice(-visibleDigits);

  return maskedPart + visiblePart;
}

export const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength) + "...";
  }
};
export const screenHeight = window.innerHeight;

export function setItemInLocalStorage(item, value) {
  window.localStorage.setItem(item, value);
}
export function getItemFromLocalStorage(item) {
  return window.localStorage.getItem(item);
}

export function setCustomerMobileNumber(mobileNumber) {
  sessionStorage.setItem('mobileNumber', mobileNumber);
}
export function getCustomerMobileNumber() {
  return sessionStorage.getItem('mobileNumber');
}
export function formatStatus(status) {
  return status
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function dateDiffInYearsAndMonths(startdate, enddate) {
  if(!enddate || !startdate) return "--";
  let startDate = moment(startdate);
  let endDate = moment(enddate);

  let years = endDate.diff(startDate, 'years');
  startDate.add(years, 'years');

  let months = endDate.diff(startDate, 'months');

  return `${years ? years + " year " : ""}${months ? months + " month" : ""}`;
}

export async function saveLead(doneState, preApprovalToken, mobileNumber, gstNo) {
  try {
      const phoneNumber = mobileNumber || getCustomerMobileNumber();
      if (!phoneNumber) return;
      const utmParams = JSON.parse(getItemFromLocalStorage("centralLosutmParams"));
      if(!utmParams || !utmParams.source) return;
      const leadData = {
        phoneNumber,
        leadSource: utmParams.source,
        medium: utmParams.campaign,
        campaign: utmParams.medium,
        last_event_done: doneState
      };
      if(gstNo) leadData.gstNumber = gstNo;
      await createLsqLead(leadData, preApprovalToken);
  } catch (e) {
      console.error(e);
  }
}

export const getmediumForUTM = () => {
  const userAgent = navigator.userAgent || navigator.vendor || (window).opera;
  let utmMedium = !window.flutter_inappwebview ? "Web" : "App";
  if (/android/i.test(userAgent)) {
    utmMedium = "Android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window).MSStream) {
    utmMedium = "iOS";
  }
  return utmMedium || "";
}

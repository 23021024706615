import { UPDATE_GET_TASKS } from "../constants";


const initialState={

}
export const getAllTasks=(state=initialState,action = {}) => {
    switch(action.type) {
        case UPDATE_GET_TASKS:
          return { ...state, ...action.payload}
        default:
          return state;
      }
}
import { GET_ADVANCEDPAID, GET_BRANCHES, GET_CONTACT_CREDIT_NOTES, GET_CONTACT_DEBIT_NOTES, GET_CONTACT_INVOICES, GET_CONTACT_PENDING_PAID_BILLS, GET_CONTACT_RECEIPTS, GET_DUE_AGEING_SUMMARY_CONTACTWISE, GET_LEDGER_PDF_DATA_BY_POUCH, GET_RECEIVABLES, GET_REMINDER_PREFILL_MAIL, GET_REMINDER_PREFILL_SMS, GET_REMINDER_PREFILL_WHATSAPP, SET_BULK_CREDFLOW_DETAILS, GET_BULK_DOWNLOAD_INVOICE, GET_TAG_VOUCHER_DATA, SET_INVOICE_FOR_FINANCE, SET_INVOICE_DATA_FOR_INVOICE_FINANCE, GET_REMINDER_MAIL_SUBJECT, SET_COMMUNICATION_HISTORY_FROM_LOKI, GET_SINGLE_DOWNLOAD_INVOICE, SET_PRIORITY_FLAG, GET_LEDGER_HTML_DATA, SET_KYC_ORG_ID, SET_APPLICATION_STATE, SET_NEW_BANNER, SET_CRM_STAGES_DATA_FOR_CUSTOMER, SET_CRM_NOTES, SET_LOS_KYC_STATUS, SET_LOS_GST, SET_SKIP_MANDATE, SET_LOS_APPLICATION_ID, SET_BUSINESS_TYPE, SET_LOS_ELIGIBLE_BANK, SET_LOS_DESIRED_LIMIT, SET_MANDATE_NACH_STATUS, SET_USER_JOURNEY_COMPLETED, SET_ALL_ORGANIZATION_USERS, SET_LOS_REFRESH } from '../constants';

const initialState = {
}

export const getCollectScreenData = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_CONTACT_RECEIPTS:
            return { ...state, contactReceipts: action.payload }
        case GET_CONTACT_INVOICES:
            return { ...state, contactInvoices: action.payload }
        case GET_DUE_AGEING_SUMMARY_CONTACTWISE:
            return { ...state, dueAgeingSummaryContactwiseData: action.payload }
        case GET_RECEIVABLES:
            return { ...state, receivablesData: action.payload }
        case GET_LEDGER_PDF_DATA_BY_POUCH:
            return { ...state, legderPdfDataByPouchDb: action.payload }
        case GET_LEDGER_HTML_DATA:
            return { ...state, ledgerHTMLData: action.payload }
        case GET_ADVANCEDPAID:
            return { ...state, advancedPaidData: action.payload }
        case GET_REMINDER_PREFILL_SMS:
            return { ...state, reminderPrefillForSMS: action.payload }
        case GET_REMINDER_PREFILL_MAIL:
            return { ...state, reminderPrefillForMAIL: action.payload }
        case GET_REMINDER_MAIL_SUBJECT:
            return { ...state, reminderSubjectForMAIL: action.payload }
        case GET_REMINDER_PREFILL_WHATSAPP:
            return { ...state, reminderPrefillForWHATSAPP: action.payload }
        case GET_BRANCHES:
            return { ...state, branchesData: action.payload }
        case GET_CONTACT_CREDIT_NOTES:
            return { ...state, contactCreditNotes: action.payload }
        case GET_CONTACT_DEBIT_NOTES:
            return { ...state, contactDebitNotes: action.payload }
        case GET_CONTACT_PENDING_PAID_BILLS:
            return { ...state, contactPendingAndPaidBills: action.payload }
        case GET_BULK_DOWNLOAD_INVOICE:
            return { ...state, getBulkInvoiceDownload: action.payload }
        case GET_SINGLE_DOWNLOAD_INVOICE:
            return {...state, getSingleInvoiceDownload:action.payload}
        case SET_BULK_CREDFLOW_DETAILS:
            return { ...state, bulkCredflowDetails: action.payload }
        case SET_INVOICE_FOR_FINANCE:
            return { ...state, invoiceForFinance: action.payload }
        case SET_INVOICE_DATA_FOR_INVOICE_FINANCE:
            return { ...state, invoiceDataForInvFinance: action.payload }
        case GET_TAG_VOUCHER_DATA:
            return { ...state, tagVoucherData: action.payload }
        case SET_COMMUNICATION_HISTORY_FROM_LOKI: 
            return { ...state, communicationHistoryFromLoki: action.payload }
        case SET_PRIORITY_FLAG:
            return { ...state, priorityFlag: action.payload }
        case SET_KYC_ORG_ID:
            return { ...state, kycOrgId: action.payload }
        case SET_APPLICATION_STATE:
            return { ...state, kycApplicationState: action.payload }    
        case SET_NEW_BANNER:
            return { ...state, showNewBanner: action.payload }
        case SET_LOS_GST:
            return { ...state, losGstNumber: action.payload }
        case SET_LOS_REFRESH:
                return { ...state, losGstRefresh: action.payload }
        case SET_LOS_KYC_STATUS:
            return { ...state, losKycStatus: action.payload }
        case SET_NEW_BANNER:
            return { ...state, showNewBanner: action.payload }    
        case SET_CRM_STAGES_DATA_FOR_CUSTOMER: 
            return { ...state, crmStagesDataForCustomer: action.payload }
        case SET_CRM_NOTES: 
            return { ...state, crmNotesOfUser: action.payload }
        case SET_SKIP_MANDATE:
            return { ...state, losSkipMandate: action.payload }
        case SET_BUSINESS_TYPE:
            return { ...state, losBusinessType: action.payload }
        case SET_LOS_APPLICATION_ID:
            return { ...state, losApplicatinId: action.payload }     
        case SET_LOS_ELIGIBLE_BANK:
            return { ...state, losEligibleBank: action.payload }     
        case SET_LOS_DESIRED_LIMIT:
            return { ...state, losDesiredLimit: action.payload }     
        case SET_MANDATE_NACH_STATUS:
            return { ...state, mandateNachStatus: action.payload }     
        case SET_USER_JOURNEY_COMPLETED:
            return { ...state, isUserJourneyCompleted: action.payload }     
        case SET_ALL_ORGANIZATION_USERS:
            return { ...state, allOrganizationUsers: action.payload }    
        default:
            return state;
    }
}
import { CF_MART_ORGANIZATION } from '../constants';

const initialState = {
  cfMartOrganization: null
}

export const cfmart = (state = initialState, action = {}) => {
  switch(action.type) {
    case CF_MART_ORGANIZATION:
      return { ...state, cfMartOrganization: action.payload,}
    default:
      return state;
  }
}
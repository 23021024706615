import {
    SET_INVOICE_PREFILL_WHATSAPP,
    SET_INVOICE_PREFILL_MAIL,
    SET_INVOICE_PREFILL_SMS,
    SET_LEDGER_CONTENT_SMS,
    SET_LEDGER_CONTENT_WHATSAPP,
  } from "../constants";
  
  export const setInvoicePrefillDataWhatsapp = (data) => ({
    type: SET_INVOICE_PREFILL_WHATSAPP,
    payload: data,
  });
  export const setInvoicePrefillDataMail = (data) => ({
    type: SET_INVOICE_PREFILL_MAIL,
    payload: data,
  });
  export const setInvoicePrefillDataSms = (data) => ({
    type: SET_INVOICE_PREFILL_SMS,
    payload: data,
  });
  export const setLedgerDataForSms = (data) => ({
    type: SET_LEDGER_CONTENT_SMS,
    payload: data,
  });
  export const setLedgerDataForWhatsapp = (data) => ({
    type: SET_LEDGER_CONTENT_WHATSAPP,
    payload: data,
  });
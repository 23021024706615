import { API_URL, MJOLNIR_API_URL, request, WANDA_API_URL } from "../helpers/utils";
import { store } from "../store";

export async function getSubscription() {
  let url = `${API_URL}/view/getMySubscriptionOnly`;
  const state = store.getState();

  const { subscriptionOnly } = state;
  if (subscriptionOnly.length) {

    return state.subscriptionOnly;
  } else {

    return request({
      url: url,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}
export async function getSubscriptionData() {
  let url = `${API_URL}/view/getMySubscriptionData`;
  const state = store.getState();
  const { subscription } = state;
  if (subscription.length) {
    return state.subscription;
  }
  else {
    return request({
      url: url,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}
export async function getSubscriptionAndSlots() {
  let url = `${MJOLNIR_API_URL}/get-subscription-details`
  return request({
    url: url,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getAllManagers(adminId) {
  const url = `${API_URL}/view/my/getAllManagers/${adminId}`;

  return request({
    url: url,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getAllOrganizationManagers(adminId) {
  const url = `${API_URL}/view/getAllOrganizationManagers`;
  const state=store.getState();
  const{allOrganizationManagers}=state
  if(allOrganizationManagers.length){
    return state.allOrganizationManagers
  }else{
   
  return request({
    url: url,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
}
}

export async function getAllOrganizations(adminId) {
  const url = `${API_URL}/view/my/getAllOrganizations/${adminId}`;

  return request({
    url: url,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updateSlotByAdmin(data) {
  const url = `${API_URL}/create/updateSlotByAdmin`;

  return request({
    url: url,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function extendTrial(id) {
  const url = `${API_URL}/create/extendTrial/${id}`;

  return request({
    url: url,
    method: "post",
    data: {},
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export const checkUserSubscription =()=>{
  const url = `${MJOLNIR_API_URL}/check-user-subscription`;
  return request({
    url: url,
    method: "get",
  });
}

export const getAllSubscriptionPlans = () => {
   const url = `${process.env.BASE_URL}/subscriber/get-subscription-plans`;
   return request({
    url: url,
    method: "get",
  });
}

export const saveSubscriptionDetails = (data) => {
  const url = `${process.env.BASE_URL}/subscriber/subscription-user-plan`;

  return request({
    url: url,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export const saveFreeSubLead = (data) => {
  const url = `${process.env.BASE_URL}/subscriber/save-free-subscription`;

  return request({
    url: url,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export const getSubscriptionDetails = (shouldSendInactive) => {
  const url = `${process.env.BASE_URL}/subscriber/eligible-plans`;
   return request({
    url: url,
    params: { shouldSendInactive },
    method: "get",
  });
}

export const fetchDiscountDetails = (discountCode, planId) => {
  const url = `${process.env.BASE_URL}/subscriber/check-discount?discountCode=${discountCode}&planId=${planId}`;
   return request({
    url: url,
    method: "get",
  });
}

import React, { Component } from 'react';
import { orbitSignUpCheck } from '../helpers/orbitUtils.JS';
import Loading from './LoadingCredflow';

class FallBack extends Component {

  componentDidMount() {
    const query = new URLSearchParams(this.props.location?.search);

    const token = query.get('token');
    const clientId = query.get('clientId');

    if(this.props.orbitRedirection && token && clientId) {
      orbitSignUpCheck(token, clientId);
    } else if(this.props.orbitRedirection && !token && !clientId) {
      window.location.href = '/login'
    }
  }

  render() {
    return (
        <div style={{
            display: "flex",
            height: '100vh',
            width: '100vw',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1200000,
            position: 'fixed'
        }}>
            <Loading />
        </div>
    );
  }
}

export default FallBack;
import {
  SET_ACCOUNTS,
  UPDATE_RECEIPTS,
  UPDATE_RECEIPT_CUSTOMERS,
  UPDATE_VOUCHERS,
  APPROVE_RECEIPT,
  DISAPPROVE_RECEIPT,
  SET_ALL_RECEIPTS,
  SET_RECEIPT_PREFILL_CONTENT,
  SET_VOUCHERS_BY_STATUS,
  SET_INVOICE_PDF_TO_HTML,
  SET_INVOICE_GUID_TO_DDR_HTML
} from '../constants';

const initialState = {
  customers: null,
  receipts: null,
  voucherNumbers: null,
  invoiceReceipt: null,
  accounts: null,
};

export const receipts = (state = initialState, action = {}) => {
  if (action.type === UPDATE_RECEIPT_CUSTOMERS) {
    return { ...state, customers: action.payload };
  } else if (action.type === UPDATE_RECEIPTS) {
    let invoiceReceipt = {};
    let receipts = action.payload;
    receipts.forEach((item) => {
      let { matchedInvoices } = item;
      matchedInvoices?.forEach((invoice) => {
        let data = {
          receiptId: item.id,
          // other fields
        };
        if (invoice.guid in invoiceReceipt) {
          invoiceReceipt[invoice.guid].push(data);
        } else {
          invoiceReceipt[invoice.guid] = [data];
        }
      });
    });
    return { ...state, receipts, invoiceReceipt };
  } else if (action.type === UPDATE_VOUCHERS) {
    return { ...state, voucherNumbers: action.payload };
  } else if (action.type === APPROVE_RECEIPT) {
    var id = action.payload;
    let { receipts } = state;
    receipts = receipts.map((item) => {
      if (item.id === id) {
        item.approved = true;
      }
      return item;
    });
    return { ...state, receipts };
  } else if (action.type === DISAPPROVE_RECEIPT) {
    let { receipts } = state;
    receipts = receipts.map((item) => {
      if (item.id === action.payload) {
      }
      item.approved = false;
      return item;
    });
    return { ...state, receipts };
  } else if (action.type === SET_ACCOUNTS) {
    return { ...state, accounts: action.payload };
  } else if (action.type === SET_ALL_RECEIPTS) {
    return { ...state, getAllReceipts: action.payload }
  } else if (action.type === SET_RECEIPT_PREFILL_CONTENT) {
    const type = action.payload.type;
    const content = action.payload.response;

    if (type == 'sms' || type == 'email' || type == 'whatsapp') {
      return { ...state, receiptPrefillContent: { [type]: content } }
    }

  } else if(action.type === SET_VOUCHERS_BY_STATUS) {
    return {...state , vouchersByStatus: action.payload}
  } else if(action.type === SET_INVOICE_PDF_TO_HTML){
    return {...state , invoicePDftoHTML: action.payload }
  } else if (action.type == SET_INVOICE_GUID_TO_DDR_HTML) {
    return { ...state, mapInvoiceGuidToDdrHtml: action.payload }
  }
  
  return state;
};

// Will contain the organization reducer
import {UPDATE_ALL_ORGANIZATION, UPDATE_TREE_ORGANIZATION, UPDATE_ORGANIZATION, SET_ALL_ORGANIZATION, SET_CONTACT_DETAILS, SET_LOKI, SET_TALLY_GROUPH_GRAPH, SET_PROCESS_EVENT_IN_MEMORY_DATA_STATUS, SET_SYNC_SOURCE_AVAILABLE_FLAG} from '../constants';

const initialState = {
}

export const organization = (state = initialState, action = {}) => {
  switch(action.type) {
    case UPDATE_ORGANIZATION:
      return { ...state, ...action.payload }
    case SET_LOKI:
      return { ...state, ...action.payload }
    case SET_TALLY_GROUPH_GRAPH:
      return { ...state, tallyGroupGraph: action.payload}
    case SET_PROCESS_EVENT_IN_MEMORY_DATA_STATUS: 
      return { ...state, inMemoryDataStatus: action.payload}
    case SET_SYNC_SOURCE_AVAILABLE_FLAG: 
      return { ...state, syncSourceFlag: action.payload}
    default:
      return state;
  }
}
export const allOrganization=(state=[],action={})=>{
  switch(action.type){
    case SET_ALL_ORGANIZATION:
      return action.payload;
    case UPDATE_ALL_ORGANIZATION:
      return{...state, ...action.payload}
    default:
      return state;
  }
}
export const fetchContactDetailsData = (state = initialState, action = {}) => {
  switch(action.type) {
    case SET_CONTACT_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
export const treeOrganization=(state=initialState,action={})=>{
  switch(action.type){
    case UPDATE_TREE_ORGANIZATION:
      return {...state, treeOfOrg: action.payload}
    default:
      return state;
  }
}
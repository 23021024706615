import {
  UPDATE_CUSTOMER, 
  UPDATE_INVOICES,
  UPDATE_CUSTOMER_DATA,
  UPDATE_TOKEN,
  UPDATE_COMMUNICATIONS,
  UPDATE_PAYMENT_OPTIONS
} from '../constants';

const initialState = {
  token: null,
  customer: null,
  dueAmount: null,
  overDueAmount: null,
  invoices: null,
  debitNotes: null,
  vendorPayments: null,
  bankDetails: null,
  communications: null,
  paymentOptions: null
}

export const customer = (state = initialState, action = {}) => {
  switch(action.type) {
    case UPDATE_CUSTOMER:
      return { ...state, customer: action.payload }
    case UPDATE_INVOICES:
      return { ...state, invoices: action.payload }
    case UPDATE_CUSTOMER_DATA: 
      let {payload} = action;
      return { ...state, ...payload}
    case UPDATE_TOKEN:
      return { ...state, token: action.payload }
    case UPDATE_COMMUNICATIONS:
      return { ...state, communications: action.payload }
    case UPDATE_PAYMENT_OPTIONS:
      return { ...state, paymentOptions: action.payload }
    default:
      return state;
  }
}
import { UPDATE_ALL_VENDORS, LIST_ALL_VENDOR_PAYMENT_DONE_TALLY } from "../constants";

export const updateAllVendors=(vendors)=>({
    type: UPDATE_ALL_VENDORS,
    payload: vendors
})

export const setListAllVendorPaymentsDoneTally=(listAllVendorPaymnetDoneTally)=>({
    type: LIST_ALL_VENDOR_PAYMENT_DONE_TALLY,
    payload: listAllVendorPaymnetDoneTally
})
import React from 'react'
import { useDispatch } from "react-redux";
import moment from "moment";
import {
    updateStockGroups,
    updateStockCategories,
    updateStockItems,
    updateStockItemsInvoicesById,
    updateStockItemsBillsById,
    updateStockItemCustomers,
    updateStockItemSuppliers
} from "./actions/stocks.actions";
import { setCalculateAgeingSummaryBucketWise, setCalculateMonthwisePurchases, setCalculateTotalCashAndBankBalance, setCalculateTotalInventoryValue, setCalculateTotalOutstanding, setCalculateTotalPayables, setCountInvoicesDueTodayData, setMonthwiseCollections, setMonthwiseOutStanding, setMonthwisePaymentTrend, setMonthwiseRecoveryTimeDistribution, setMonthwiseSales, setProjectedCollection, setTopCustomersData, setBillsDueTodayCount, setBadDebtorsCount } from "./actions/dashboard.actions";
import { DARKHOLD_URL, emitCustomEvent, getQRCodeStringToBase64, PUPPETTER_API_URL, request, ULTRON_API_URL, WANDA_API_URL } from './helpers/utils';

import { setAdvancePaidResponse, setBranchesResponse, setContactInvoices, setContactReceipts, setReceivablesResponse, setDueAgeingSummaryContactwise, setContactCreditNotes, setContactDebitNotes, setContactPendingAndPaidBills, setLedgerPdfDataByPouch, setReminderPrefillForSms, setReminderPrefillForWhatsapp, setReminderPrefillForMail, setBulkCredflowDetails, setSingleInvoiceDownload, setMultipleInvoiceDownload, setTagVoucherData, setInvoiceForFinance, setInvoiceDataForInvFinance, setReminderMailSubject, setCommunicationHistoryFromLoki, setPriorityFlag, setLedgerHTMLData, setLosEligibleBank } from './actions/collect.actions';
import { updateReceipts, updateVouchers, setAccounts, setVouchersByStatus, setInvoicePDftoHTML, updateCustomers, setMapInvoiceGuidToDdrHtml } from './actions/receipts.actions';
import { ProcessEventInMemoryDataStatus, setLOKI, setSyncSourceFlag } from './actions/organization.action';
import { setContactsRTO, setDebtorSummaryReport, setSalesManagerReport, setProjectedCollectionReport, setOverviewOfSalesOfDebtor, setBusyTotalSale } from './actions/debtorSummary.actions';
import { setAllReceipts, setPrefillContent } from './actions/receipts.actions';
import { setListAllVendorPaymentsDoneTally, updateAllVendors } from './actions/paySceen.actions';
import { getAllUsers } from './api/user';
import { updateAllCustomersOfOrg, updateDueAgeingSummaryForContact } from './actions/customers.actions';
import { setInvoicePrefillDataMail, setInvoicePrefillDataSms, setInvoicePrefillDataWhatsapp, setLedgerDataForSms, setLedgerDataForWhatsapp } from './actions/invoicePrefill.actions';

export const WorkerListener = () => {
    const dispatch = useDispatch();
    if (broadcast) {
        localStorageBroadcast.onmessage = (event) => {
            if (event.data && event.data.method === 'setDataToLocalStorage') {
                setDataToLocalStorage(event.data.response.key, event.data.response.value)
            }
            else if (event.data && event.data.method === 'getDataFromLocalStorage') {
                getDataFromLocalStorage(event.data.response?.key, event.data.response?.identifier)
            }
            else if (event.data && event.data.method === 'removeDataFromLocalStorage') {
                removeDataFromLocalStorage(event.data.response.key)
            }
        };
        function setDataToLocalStorage(key, data) {
            localStorage.setItem(key, data);
        }

        function getDataFromLocalStorage(key, identifier) {
            const result = localStorage.getItem(key);
            localStorageBroadcast.postMessage({
                method: 'getDataFromLocalStorage',
                response: result,
                identifier
            });
        }

        function removeDataFromLocalStorage(key) {
            localStorage.removeItem(key);
        }

        broadcast.onmessage = async (event) => {
            try {
                if (event.data) {
                    // if (event.data.tabId && event.data.tabId == sessionStorage.tabID) {
                    // console.log("first ", event.data.method, " => ", event.data.response);
                    console.log(event.data.method, event.data.statusCode);

                    // emit custom event used to call broadcast asynchronously
                    emitCustomEvent(event.data.method, event);

                    if (event.data.method == "inMemory") {
                        let methodResponse = event.data.response
                        const orgId = localStorage.getItem('organizationId');
                        if (methodResponse.inMemory && (methodResponse.organizationId && (orgId == methodResponse.organizationId)) || (!methodResponse.organizationId)) {
                            LOKI_IN_MEMORY = true
                            dispatch(setLOKI({ lokiInMemory: true }));
                        } else {
                            LOKI_IN_MEMORY = false
                            dispatch(setLOKI({ lokiInMemory: false }));
                        }
                    } else if (event.data.method === "fetchUrlFromServer") {
                        dispatch(setSyncSourceFlag(true))
                    } else if (event.data.method === "progressEvent") {
                        dispatch(ProcessEventInMemoryDataStatus(event.data.response))
                    } else if (event.data.method === "getAllStockGroups") {
                        if (event.data.statusCode === 200) {
                            dispatch(updateStockGroups(event.data.response));
                        }
                    } else if (event.data && event.data.method === "getAllStockCategories") {
                        if (event.data.statusCode === 200) {
                            dispatch(updateStockCategories(event.data.response));
                        }
                    } else if (event.data && event.data.method === "getStockItemData") {
                        if (event.data.statusCode === 200) {
                            dispatch(updateStockItems(event.data.response));
                        }
                    } else if (event.data && event.data.method === 'fetchMonthwiseSalesDistribution') {
                        if (event.data.statusCode === 200) {
                            dispatch(setMonthwiseSales(event.data.response.salesData))
                        }
                    } else if (event.data && event.data.method === 'fetchMonthwiseCollectionDistribution') {
                        if (event.data.statusCode === 200) {
                            dispatch(setMonthwiseCollections(event.data.response.collectionData))
                        }
                    } else if (event.data && event.data.method === 'fetchMonthwiseOutstandingDistribution') {
                        if (event.data.statusCode === 200) {
                            dispatch(setMonthwiseOutStanding(event.data.response))
                        }
                    } else if (event.data && event.data.method === 'fetchMonthwiseRecoveryTimeDistribution') {
                        if (event.data.statusCode === 200) {
                            dispatch(setMonthwiseRecoveryTimeDistribution(event.data.response))
                        }
                    } else if (event.data && event.data.method === 'calculateAgeingSummaryBucketWise') {
                        if (event.data.statusCode === 200) {
                            dispatch(setCalculateAgeingSummaryBucketWise(event.data.response))
                        }
                    } else if (event.data && event.data.method === 'calculateTotalOutstanding') {
                        dispatch(setCalculateTotalOutstanding(event.data.response))
                    } else if (event.data && event.data.method === 'calculateTotalPayables') {
                        if (event.data.statusCode === 200) {
                            dispatch(setCalculateTotalPayables(event.data.response))
                        }
                    } else if (event.data && event.data.method === 'calculateTotalInventoryValue') {
                        if (event.data.statusCode === 200) {
                            dispatch(setCalculateTotalInventoryValue(event.data.response))
                        }
                    } else if (event.data && event.data.method === 'calculateTotalCashAndBankBalance') {
                        if (event.data.statusCode === 200) {
                            dispatch(setCalculateTotalCashAndBankBalance(event.data.response))
                        }
                    } else if (event.data && event.data.method === 'countInvoicesDueToday') {
                        if (event.data.statusCode === 200) {
                            dispatch(setCountInvoicesDueTodayData(event.data.response))
                        }
                    } else if (event.data && event.data.method === 'getTopCustomers') {
                        if (event.data.statusCode === 200) {
                            dispatch(setTopCustomersData(event.data.response))
                        }
                    } else if (event.data && event.data.method === 'getBadDebtorsCount') {
                        if (event.data.statusCode === 200) {
                            dispatch(setBadDebtorsCount(event.data.response.count))
                        }
                    } else if (event.data && event.data.method === 'countBillsDueToday') {
                        if (event.data.statusCode === 200) {
                            dispatch(setBillsDueTodayCount(event.data.response))
                        }
                    } else if (event.data && event.data.method === 'monthwisePaymentTrend') {
                        if (event.data.statusCode === 200) {
                            dispatch(setMonthwisePaymentTrend(event.data.response))
                        }
                    } else if (event.data && event.data.method === 'calculateMonthwisePurchases') {
                        if (event.data.statusCode === 200) {
                            dispatch(setCalculateMonthwisePurchases(event.data.response))
                        }
                    } else if (event.data && event.data.method === 'getProjectedCollection') {
                        if (event.data.statusCode === 200) {
                            dispatch(setProjectedCollection(event.data.response.totalSum))
                        }
                    } else if (event.data && event.data.method === 'getContactReceipts') {
                        if (event.data.statusCode === 200) {
                            dispatch(setContactReceipts(event.data.response))
                        }
                    } else if (event.data && event.data.method === 'getContactInvoices') {
                        if (event.data.statusCode === 200) {
                            let dataToSend = event.data.response
                            dataToSend.exportCSVAllInvoices = event.data.args.payload.exportCSVAllInvoices
                            dataToSend.screen = event.data.args.payload.screen
                            dispatch(setContactInvoices(dataToSend))
                        }
                    } else if (event.data && event.data.method === 'getContactCreditNotes') {
                        if (event.data.statusCode === 200) {
                            let dataToSend = event.data.response
                            dispatch(setContactCreditNotes(dataToSend))
                        }
                    } else if (event.data && event.data.method === 'getContactDebitNotes') {
                        if (event.data.statusCode === 200) {
                            let dataToSend = event.data.response;
                            dispatch(setContactDebitNotes(dataToSend))
                        }
                    } else if (event.data && event.data.method === 'getContactBills') {
                        if (event.data.statusCode === 200) {
                            let dataToSend = [];
                            let url = `${ULTRON_API_URL}/get-bills-pouch-payable-bank-data`;
                            if (event.data.response.contactIdsSet.length) {
                                let payableBankDataOfBills = await request({
                                    url: url,
                                    data: { contactIdsSet: event.data.response.contactIdsSet },
                                    method: "post"
                                });
                                if (payableBankDataOfBills.status == 200) {
                                    if (payableBankDataOfBills.data.length) {
                                        event.data.response.value.map((data) => {
                                            data.payableBanks = [];
                                            for (let payableBank of payableBankDataOfBills.data) {
                                                if (data.contactId == payableBank.contactId) {
                                                    data.payableBanks = payableBank.data
                                                }
                                            }
                                            dataToSend.push(data)
                                        })
                                    } else {
                                        event.data.response.value.map((data) => {
                                            data.payableBanks = []
                                            dataToSend.push(data)
                                        })
                                    }
                                }
                            }
                            event.data.response.value = dataToSend
                            event.data.response.payload = event.data.args.payload
                            dispatch(setContactPendingAndPaidBills(event.data.response))
                        }
                    } else if (event.data && event.data.method === 'getContactBillsV2') {
                        if (event.data.statusCode === 200) {
                            let dataToSend = [];
                            let url = `${ULTRON_API_URL}/get-bills-pouch-payable-bank-data`;
                            if (event.data.response.contactIdsSet.length) {
                                let payableBankDataOfBills = await request({
                                    url: url,
                                    data: { contactIdsSet: event.data.response.contactIdsSet },
                                    method: "post"
                                });
                                if (payableBankDataOfBills.status == 200) {
                                    if (payableBankDataOfBills.data.length) {
                                        event.data.response.value.map((data) => {
                                            data.payableBanks = [];
                                            for (let payableBank of payableBankDataOfBills.data) {
                                                if (data.contactId == payableBank.contactId) {
                                                    data.payableBanks = payableBank.data
                                                }
                                            }
                                            dataToSend.push(data)
                                        })
                                    } else {
                                        event.data.response.value.map((data) => {
                                            data.payableBanks = []
                                            dataToSend.push(data)
                                        })
                                    }
                                }
                            }
                            event.data.response.value = dataToSend
                            event.data.response.payload = event.data.args.payload
                            dispatch(setContactPendingAndPaidBills(event.data.response))
                        }
                    } else if (event.data && event.data.method === 'dueAgeingSummaryContactwiseUsingContactGuidHelper') {
                        if (event.data.statusCode === 200) {
                            // let dataToSend = event.data.response;
                            // dataToSend.fetchAll = event.data.args.fetchAll;
                            // dataToSend.stepOne = true
                            dispatch(setDueAgeingSummaryContactwise({ ...event.data.response, fetchAll: event.data.args.fetchAll, stepOne: true }))
                        }
                    } else if (event.data && event.data.method === 'dueAgeingSummaryContactwise') {
                        if (event.data.statusCode === 200) {
                            let dataToSend = event.data.response;
                            dataToSend.fetchAll = event.data.args.fetchAll;
                            dataToSend.stepOne = true
                            dispatch(setDueAgeingSummaryContactwise({ ...dataToSend }))
                            let contactIds = []
                            let contactGuids = []
                            let contactGuidToMap = new Map()
                            dataToSend.output.forEach((data) => {
                                contactIds.push(data.contact_id.toString())
                                contactGuids.push(data.guid)
                                contactGuidToMap.set(data.guid, data.contact_id.toString())
                            })
                            let unAvailableDataUrl = `${WANDA_API_URL}/contact-details/get-actions-contact-wise`
                            let unAvailableResponse;
                            if (!event.data.args.stopActionApi) {
                                if (contactIds.length) {
                                    try {
                                        unAvailableResponse = await request({
                                            url: unAvailableDataUrl,
                                            method: "post",
                                            //TODO will add contactGuids once force update done
                                            data: { contactIds, fetchAll: contactGuids?.length <= 10 ? false : true }
                                        });
                                    } catch (error) {
                                        console.log(error);
                                    }
                                }
                            }
                            if (unAvailableResponse?.status == 200) {
                                dataToSend.output = dataToSend.output.map((data) => {
                                    for (const unAvailableValue in unAvailableResponse?.data) {
                                        let contactId = contactGuidToMap.get(unAvailableValue) || unAvailableValue;
                                        if (data.contact_id == contactId) {
                                            if (unAvailableResponse.data[unAvailableValue]) {
                                                data.actions = [unAvailableResponse.data[unAvailableValue]]
                                            }
                                            return data
                                        }
                                    }
                                })
                            }
                            let guidsForRetailerOnboardStatus = []
                            dataToSend.output.forEach((data) => {
                                guidsForRetailerOnboardStatus.push(data.guid)
                            })
                            dataToSend.stepOne = false;
                            dispatch(setDueAgeingSummaryContactwise({ ...dataToSend }));
                            let retailerOnboardingStatusUrl = `${DARKHOLD_URL}/get-retailer-onboarding-status`
                            let retailerOnboardingStatusResponse;
                            if (!event.data.args.stopActionApi) {
                                if (guidsForRetailerOnboardStatus.length) {
                                    try {
                                        retailerOnboardingStatusResponse = await request({
                                            url: retailerOnboardingStatusUrl,
                                            method: "post",
                                            data: { ledgerGuids: guidsForRetailerOnboardStatus }
                                        });
                                    } catch (error) {
                                        console.log(error);
                                    }
                                }
                            }

                            if (retailerOnboardingStatusResponse) {
                                dataToSend.output = dataToSend.output.map((data) => {
                                    for (const retailerStatus in retailerOnboardingStatusResponse?.data) {
                                        if (data.guid === retailerStatus) {
                                            data.financeInvRetailerOnboardingStatus = retailerOnboardingStatusResponse.data[retailerStatus]
                                            return data
                                        }
                                    }
                                    data.financeInvRetailerOnboardingStatus = "REQUSET_FORON_BOARDING"
                                    return data
                                })
                            }
                            dataToSend.stepOne = true;
                            dispatch(setDueAgeingSummaryContactwise({ ...dataToSend }))

                        }
                    } else if (event.data && event.data.method === "getAllReceipts") {
                        if (event.data.statusCode === 200) {
                            const responseData = event.data.response;
                            dispatch(setAllReceipts(responseData));
                        }
                    } else if (event.data && event.data.method === "paymentAdvicePrefillContent") {
                        if (event.data.statusCode === 200) {
                            const responseData = event.data.response;
                            dispatch(setPrefillContent({
                                response: responseData,
                                type: event.data.args.communicationType
                            }));
                        }
                    } else if (event.data && event.data.method === 'getLedgerReport') {
                        if (event.data.statusCode === 200) {
                            const responseData = event.data.response;
                            
                            // Extract contact IDs and GUIDs from the responseData
                            let contactIds = [];
                            let contactGuids = [];
                            let contactGuidToMap = new Map();
                            
                            if (responseData.arrayData && responseData.arrayData.length > 0) {
                                responseData.arrayData.forEach((item) => {
                                    if (item.segregatedDue && item.segregatedDue.length > 0) {
                                        item.segregatedDue.forEach((due) => {
                                            contactIds.push(due.contact_id.toString());
                                            contactGuids.push(due.guid);
                                            contactGuidToMap.set(due.guid, due.contact_id.toString());
                                        });
                                    }
                                });
                            }
                            
                            // Fetch actions data
                            if (contactIds.length) {
                                let unAvailableDataUrl = `${WANDA_API_URL}/contact-details/get-actions-contact-wise`;
                                let unAvailableResponse;
                                
                                try {
                                    unAvailableResponse = await request({
                                        url: unAvailableDataUrl,
                                        method: "post",
                                        data: { contactIds, fetchAll: contactGuids.length <= 10 ? false : true }
                                    });
                                } catch (error) {
                                    console.log(error);
                                }
                                
                                // Combine actions data with the responseData
                                if (unAvailableResponse?.status === 200) {
                                    responseData.arrayData = responseData.arrayData.map((item) => {
                                        if (item.segregatedDue && item.segregatedDue.length > 0) {
                                            item.segregatedDue = item.segregatedDue.map((due) => {
                                                const contactId = contactGuidToMap.get(due.guid) || due.contact_id.toString();
                                                const actions = unAvailableResponse.data[contactId];
                                                
                                                if (actions) {
                                                    due.actions = actions;
                                                }
                                                
                                                return due;
                                            });
                                        }
                                        return item;
                                    });
                                }
                            }
                            
                            // Dispatch the combined data with actions after fetching
                            dispatch(setLedgerPdfDataByPouch(responseData));
                        }
                    } else if (event.data && event.data.method === 'getLedgerData') {
                        if (event.data.statusCode === 200) {
                            dispatch(setLedgerHTMLData(event.data.response));
                        }

                    } else if (event.data && event.data.method === 'getReminderPrefill') {
                        if (event.data.statusCode === 200) {
                            const responseData = event.data.response;
                            if (event.data.args.type == 'sms') {
                                dispatch(setReminderPrefillForSms(responseData));
                            }
                            else if (event.data.args.type == 'whatsapp') {
                                dispatch(setReminderPrefillForWhatsapp(responseData));
                            }
                            else if (event.data.args.type == 'email') {
                                dispatch(setReminderMailSubject(responseData.subject));
                                dispatch(setReminderPrefillForMail(responseData.mailContentHtml));
                            }
                        }
                    } else if (event.data && event.data.method === 'totalDueAndAgeingSummary') {
                        if (event.data.args.uniqueIdentifer === "receivables") {
                            if (event.data.statusCode === 200) {
                                let responseData = event.data.response;
                                dispatch(setReceivablesResponse(responseData));
                            }
                        } else if (event.data.args.uniqueIdentifer === "advance_paid") {
                            if (event.data.statusCode === 200) {
                                let responseData = event.data.response
                                dispatch(setAdvancePaidResponse(responseData));
                            }
                        } else if (event.data.args.uniqueIdentifer === "branches") {
                            if (event.data.statusCode === 200) {
                                let responseData = event.data.response
                                dispatch(setBranchesResponse(responseData));
                            }
                        }
                    } else if (event.data && event.data.method === "getDebtorSummaryReport") {
                        if (event.data.statusCode === 200) {
                            dispatch(setDebtorSummaryReport(event.data.response))
                        }
                    }else if (event.data && event.data.method === "getSaleAmountDateWise") {
                        if (event.data.statusCode === 200) {
                            dispatch(setBusyTotalSale(event.data.response))
                        }
                    } else if (event.data && event.data.method === "getContactsRTO") {
                        if (event.data.statusCode === 200) {
                            dispatch(setContactsRTO(event.data.response))
                        }
                    } else if (event.data && event.data.method === "getSalesManagerReport") {
                        if (event.data.statusCode === 200) {
                            let dataToSend = event.data.response.dataToSend;
                            let url = `${WANDA_API_URL}/sales-manager-report-account-managerwise`;
                            let response;
                            if (dataToSend.length) {
                                response = await request({
                                    url: url,
                                    method: "post",
                                    headers: {},
                                    data: { start: event.data.args.payload.startDate, end: event.data.args.payload.endDate, accountManagers: event.data.response.accManagersWithContactsForApiArray }
                                });
                            }
                            if (response && response.status == 200) {
                                let responseMap = new Map();
                                response.data.forEach((res) => {
                                    responseMap.set(res.accountManager, res);
                                })
                                for (const dataOfContact of dataToSend) {
                                    let matchingData = responseMap.get(dataOfContact.id);
                                    if (dataOfContact.id == matchingData.accountManager) {
                                        dataOfContact.salesTarget = matchingData.salesTarget;
                                        dataOfContact.collectionTarget = matchingData.collectionTarget;
                                    }
                                }
                                responseMap = null;
                            }
                            dispatch(setSalesManagerReport(dataToSend))
                        }
                    } else if (event.data && event.data.method === "getProjectedCollectionReport") {
                        if (event.data.statusCode === 200) {
                            dispatch(setProjectedCollectionReport(event.data.response))
                        }
                    } else if (event.data && event.data.method === "getBulkContactDetails") {
                        if (event.data.statusCode === 200) {
                            let dataToSend = event.data.response;
                            let resposenseForCredflowDetails;
                            if (event.data.args?.contactIds?.length) {
                                const url = `${WANDA_API_URL}/contact-details/get-bulk-credflow-details`;
                                resposenseForCredflowDetails = await request({
                                    url: url,
                                    method: "post",
                                    data: { contactIds: event.data.args.contactIds },
                                })
                            }
                            if (resposenseForCredflowDetails && resposenseForCredflowDetails.status == 200) {
                                let responseMap = new Map();
                                resposenseForCredflowDetails?.data?.forEach((res) => {
                                    let CredflowDet = responseMap.get(res.contactId);
                                    if (CredflowDet) {
                                        CredflowDet.push(res)
                                    } else {
                                        responseMap.set(res.contactId, [res])
                                    }
                                })
                                for (const dataOfContact of dataToSend) {
                                    let matchingData = responseMap.get(`${dataOfContact.id}`);
                                    if (matchingData && matchingData?.length) {
                                        dataOfContact.CredflowDetails = matchingData;
                                    } else {
                                        dataOfContact.CredflowDetails = [];
                                    }
                                }
                                responseMap = null;
                            }
                            dispatch(setBulkCredflowDetails(dataToSend));
                        }
                    } else if (event.data && event.data.method === "bulkDownloadInvoices") {
                        if (event.data.statusCode === 200) {
                            let invoiceArray = event.data.response;
                            if (event.data.args?.screen && event.data.args?.screen == "getInvoicePDftoHTML") {
                                let dataToSend = event.data.response;
                                dataToSend.map((invoice) => {
                                    if (invoice.irnAckDate) {
                                        invoice.irnAckDate = moment(invoice.irnAckDate).format('DD/MM/YYYY');
                                    }
                                })
                                dispatch(setInvoicePDftoHTML(dataToSend));
                                return;
                            }
                            if (invoiceArray && invoiceArray instanceof Array) {
                                const updatedInvoiceArray = await Promise.all(invoiceArray.map(async (invoice) => {
                                    if (invoice.irnQRCode) {
                                        const base64Url = await getQRCodeStringToBase64(invoice.irnQRCode);
                                        invoice.irnQRCode = base64Url;
                                    }
                                    return invoice;
                                }));
                                const response = await request({
                                    method: "post",
                                    url: `${PUPPETTER_API_URL}/monthlyInvoicePdf`,
                                    data: {
                                        invoice: updatedInvoiceArray,
                                        showPartNumber: event.data.args.showPartNumber ? true : false,
                                        showBatchNumber: event.data.args.showBatchNumber ? true : false
                                    },
                                    headers: {
                                        "Content-Type": "application/json"
                                    },
                                    maxContentLength: Infinity,
                                    maxBodyLength: Infinity,
                                    responseType: "arraybuffer"
                                });
                                dispatch(setMultipleInvoiceDownload(response.data));
                            }
                        }
                    } else if (event.data && event.data.method === "getContactPaymentsDone") {
                        if (event.data.statusCode === 200) {
                            dispatch(setListAllVendorPaymentsDoneTally(event.data.response))
                        }
                    } else if (event.data && event.data.method === 'getAllCustomers') {
                        if (event.data.statusCode === 200) {
                            if (event.data.args?.screen && event.data.args?.screen == "CustomerScreen") {
                                let dataToSend = event.data.response.customers;
                                dataToSend = dataToSend.sort((a, b) => b.closingBalance - a.closingBalance);
                                let resposenseForCredflowDetails;
                                let contactIds = [];
                                let argumentsOfFunction = event.data.args;
                                dataToSend = dataToSend.slice(argumentsOfFunction.pagination * argumentsOfFunction.pageSize, argumentsOfFunction.pagination * argumentsOfFunction.pageSize + argumentsOfFunction.pageSize);
                                if (dataToSend.length > 0) {
                                    dataToSend = dataToSend.filter((contact) => {
                                        contact.closingBalance = contact.isDebitCloseBal ? contact.closingBalance : 0;
                                        if (contact.name == "Cash-in-Hand") {
                                            return false;
                                        }
                                        contactIds.push(contact.id)
                                        return true;
                                    });
                                }
                                if (contactIds?.length && !event.data.args.rowClicked) {
                                    const url = `${WANDA_API_URL}/contact-details/get-bulk-credflow-details`;
                                    resposenseForCredflowDetails = await request({
                                        url: url,
                                        method: "post",
                                        data: { contactIds },
                                    })
                                }
                                if (resposenseForCredflowDetails && resposenseForCredflowDetails.status == 200) {
                                    let responseMap = new Map();
                                    resposenseForCredflowDetails?.data?.forEach((res) => {
                                        let CredflowDet = responseMap.get(res.contactId);
                                        if (CredflowDet) {
                                            CredflowDet.push(res)
                                        } else {
                                            responseMap.set(res.contactId, [res])
                                        }
                                    })
                                    let accountManagers = await getAllUsers();
                                    if (accountManagers?.data?.length) {
                                        accountManagers = accountManagers.data
                                    }
                                    let accountManagerMap = new Map();
                                    accountManagers?.forEach((accountManager) => {
                                        accountManagerMap.set(accountManager.userId, accountManager)
                                    })
                                    for (const dataOfContact of dataToSend) {
                                        let matchingData = responseMap.get(`${dataOfContact.id}`);
                                        if (matchingData && matchingData?.length) {
                                            dataOfContact.CredflowDetails = matchingData;
                                        } else {
                                            dataOfContact.CredflowDetails = [];
                                        }
                                        let accountManager = accountManagerMap.get(dataOfContact.accountManagerId)
                                        if (accountManager) {
                                            dataOfContact.User = accountManager;
                                        }
                                        dataOfContact.contactId = dataOfContact.id;
                                    }
                                    accountManagerMap = null;
                                    responseMap = null;
                                }
                                dispatch(updateAllCustomersOfOrg(dataToSend))
                            } else {
                                dispatch(updateCustomers(event.data.response.customers))
                            }
                        }
                    } else if (event.data && event.data.method === 'getAccounts') {
                        if (event.data.statusCode === 200) {
                            dispatch(setAccounts(event.data.response.accounts))
                        }
                    } else if (event.data && event.data.method === 'getAllVoucherNumbers') {
                        if (event.data.statusCode === 200) {
                            dispatch(updateVouchers(event.data.response.voucherNumbers))
                        }
                    } else if (event.data && event.data.method === "getDueAgeingSummaryForContact") {
                        if (event.data.statusCode == 200) {
                            dispatch(updateDueAgeingSummaryForContact(event.data.response));
                        }
                    } else if (event.data && event.data.method === 'getGeneratedReceipts') {
                        if (event.data.statusCode === 200) {
                            dispatch(updateReceipts(event.data.response.generatedReceipts));
                        }
                    } else if (event.data && event.data.method === "getStockItemContacts") {
                        if (event.data.statusCode === 200) {
                            if (event.data.args.type === "customers")
                                dispatch(updateStockItemCustomers(event.data.response));
                            else
                                dispatch(updateStockItemSuppliers(event.data.response));
                        }
                    } else if (event.data && event.data.method === 'getVouchersByStatusAndType') {
                        if (event.data.statusCode === 200) {
                            dispatch(setVouchersByStatus(event.data.response));
                        }
                    } else if (event.data && event.data.method === 'getStockItemsByIdOnBasisOfType') {
                        if (event.data.statusCode === 200) {
                            if (event.data.args.type == 'invoice') {
                                dispatch(updateStockItemsInvoicesById(event.data.response));
                            }
                            else if (event.data.args.type == 'bill') {
                                dispatch(updateStockItemsBillsById(event.data.response));
                            }
                        }
                    } else if (event.data && event.data.method === 'getTagVoucherToContactAndStockItemsOfContact') {
                        if (event.data.statusCode === 200) {
                            dispatch(setTagVoucherData(event.data.response));
                        }
                    } else if (event.data && event.data.method === 'getInvoicesForFinancing') {
                        if (event.data.statusCode === 200) {
                            dispatch(setInvoiceForFinance(event.data.response));
                        }
                    } else if (event.data && event.data.method === 'createInvoicesDataForIF') {
                        if (event.data.statusCode === 200) {
                            dispatch(setInvoiceDataForInvFinance(event.data.response));
                        }
                    } else if (event.data && event.data.method === 'communicationHistory') {
                        if (event.data.statusCode === 200) {
                            dispatch(setCommunicationHistoryFromLoki(event.data.response));
                        }
                    } else if (event.data && event.data.method === 'getVendors') {
                        if (event.data.statusCode === 200) {
                            let closingBalance = 0;
                            if (event.data.response.contactIdsSet.length && !event.data?.args?.notCallApi) {
                                let urlForPaybles = `${ULTRON_API_URL}/get-bills-pouch-payable-bank-data`;
                                let payableBankDataOfBills = await request({
                                    url: urlForPaybles,
                                    data: { contactIdsSet: event.data.response.contactIdsSet },
                                    method: "post"
                                });
                                const url = `${WANDA_API_URL}/contact-details/get-bulk-credflow-details`;
                                let resposenseForCredflowDetails = await request({
                                    url: url,
                                    data: { contactIds: event.data.response.contactIdsSet },
                                    method: "post"
                                })
                                if (payableBankDataOfBills?.status == 200 && resposenseForCredflowDetails?.status == 200) {
                                    let payableBankDataOfBillsMap = new Map();
                                    let resposenseForCredflowDetailsMap = new Map();
                                    if (payableBankDataOfBills.data.length) {
                                        payableBankDataOfBills.data.forEach((bankDetailsData) => {
                                            let bankDetails = payableBankDataOfBillsMap.get(bankDetailsData.contactId);
                                            if (bankDetails) {
                                                bankDetails.push(bankDetailsData)
                                            } else {
                                                payableBankDataOfBillsMap.set(bankDetailsData.contactId, [bankDetailsData])
                                            }
                                        });
                                    }
                                    if (resposenseForCredflowDetails.data.length) {
                                        resposenseForCredflowDetails.data.forEach((bulkCredflowDetails) => {
                                            let contactDetails = resposenseForCredflowDetailsMap.get(bulkCredflowDetails.contactId);
                                            if (contactDetails) {
                                                contactDetails.push(bulkCredflowDetails)
                                            } else {
                                                resposenseForCredflowDetailsMap.set(bulkCredflowDetails.contactId, [bulkCredflowDetails])
                                            }
                                        })
                                    }
                                    let accountManagers = await getAllUsers();
                                    if (accountManagers?.data?.length) {
                                        accountManagers = accountManagers.data
                                    }
                                    let accountManagerMap = new Map();
                                    accountManagers?.forEach((accountManager) => {
                                        accountManagerMap.set(accountManager.userId, accountManager)
                                    })
                                    event.data.response.vendors.map((vendor) => {
                                        let bankDetails = payableBankDataOfBillsMap.get(vendor.id);
                                        if (bankDetails && bankDetails.length) {
                                            vendor.payableBanks = bankDetails[0]?.data;
                                        } else {
                                            vendor.payableBanks = [];
                                        }
                                        let bulkCredflowDetails = resposenseForCredflowDetailsMap.get(vendor.id);
                                        if (bulkCredflowDetails) {
                                            vendor.CredflowDetails = bulkCredflowDetails;
                                        } else {
                                            vendor.CredflowDetails = [];
                                        }
                                        let accountManager = accountManagerMap.get(vendor.accountManagerId)
                                        if (accountManager) {
                                            vendor.User = accountManager
                                        }
                                        closingBalance += vendor.closingbalance;
                                    });
                                    resposenseForCredflowDetailsMap = null;
                                    payableBankDataOfBillsMap = null;
                                    accountManagerMap = null;
                                }
                            }
                            event.data.response.closingBalance = closingBalance;
                            dispatch(updateAllVendors(event.data.response));
                        }
                    }
                    else if (event.data && event.data.method === 'invoicePrefillData') {
                        if (event.data.statusCode === 200) {
                            if (event.data.args.typeFor == 'Invoice' && event.data.args.type == 'whatsApp') {
                                dispatch(setInvoicePrefillDataWhatsapp(event.data.response));
                            }
                            else if (event.data.args.typeFor == 'Invoice' && event.data.args.type == 'mail') {
                                dispatch(setInvoicePrefillDataMail(event.data.response));
                            }
                            else if (event.data.args.typeFor == 'Invoice' && event.data.args.type == 'SMS') {
                                dispatch(setInvoicePrefillDataSms(event.data.response));
                            }
                            else if (event.data.args.typeFor == 'Ledger' && event.data.args.type == 'SMS') {
                                dispatch(setLedgerDataForSms(event.data.response));
                            }
                            else if (event.data.args.typeFor == 'Ledger' && event.data.args.type == 'whatsApp') {
                                dispatch(setLedgerDataForWhatsapp(event.data.response));
                            }
                        }
                    }
                    else if (event.data && event.data.method === 'mapContactGuidToPriority') {
                        if (event.data.statusCode === 200) {
                            dispatch(setPriorityFlag(event.data.response));
                        }
                    }
                    else if (event.data && event.data.method === 'getOverviewOfSalesByDebtor') {
                        if (event.data.statusCode === 200) {
                            dispatch(setOverviewOfSalesOfDebtor(event.data.response));
                        }
                    }
                    else if (event.data && event.data.method === 'getEligibleBanks') {
                        if (event.data.statusCode === 200) {
                            dispatch(setLosEligibleBank(event.data.response));
                        }
                    }
                    else if (event.data && event.data.method === 'getInvoiceFinanceHTML') {
                        if (event.data.statusCode === 200) {
                            dispatch(setMapInvoiceGuidToDdrHtml(event.data.response));
                        }
                    }
                }
            } catch (err) {
                console.log(err, event.data.method);
            }
        };
    }
    return <></>
}

export default WorkerListener

import {UPDATE_CUSTOMERS, UPDATE_DUE_AGEING_SUMMARY_FOR_CONTACT} from '../constants';

export const updateAllCustomersOfOrg = (allCustomersOfOrg) => ({
  type: UPDATE_CUSTOMERS,
  payload: allCustomersOfOrg
});

export const updateDueAgeingSummaryForContact = (dueAgeingSummaryForContact) => ({
  type: UPDATE_DUE_AGEING_SUMMARY_FOR_CONTACT,
  payload: dueAgeingSummaryForContact
});
import CleverTap from './clevertapService';
import { firebaseAnalytics } from '../firebaseConfig';
import getScreeName from '../helpers/screenName';
import { EventsLog, IntegrationType, EventCategory } from '@credflow/mantis';
import { isAuthenticated } from '../helpers/utils';
import { isMobile } from 'react-device-detect';
import { amplitudeLogEvent } from './amplitudeService';
const mode = 'live';

const eventObj = new EventsLog(process.env.NICK_FURY_URL, process.env.EVENT_TOKEN, IntegrationType.BROWSER);

const analytics = {
  registerUser: function(userData){
    eventObj.register(userData.organizationId, userData.userId);

    const clevertapUserObj = {
      Site: {
        Name: userData.user.name,
        Email: userData.user.email,
        Identity: userData.user.id,
        orgId: userData.organizationId,
        Phone: `+91${userData.user.phone}`,
        accountingSoftware: userData.accountingSoftware,
        Referal: userData.user.referralCode,
        Created_at: userData.createdAt
      }
    };

    if(mode == 'live'){
      CleverTap.registerUser(clevertapUserObj);
    }else{
      console.log(clevertapUserObj);
    }
  },

  setProfile: function(userData) {
    const clevertapUserObj = {
      Site: {
        Name: userData.user.name,
        Email: userData.user.email,
        Identity: userData.user.id,
        orgId: userData.organizationId,
        Phone: `+91${userData.user.phone}`,
        accountingSoftware: userData.accountingSoftware,
        Referal: userData.user.referralCode,
        Created_at: userData.createdAt
      }
    };
    if (mode === 'live') {
      CleverTap.setProfile(clevertapUserObj);
      try{
        window.clarity("identify", userData.user.id);
    } catch(err){}
    } else {

    }
  },

  registerNonUser: function(){
    eventObj.register();
  },

  registerCustomer: function(organizationId, customerId){
    eventObj.register(organizationId, undefined, customerId);
  },

  event: function(eventName, eventData={}, analyticServices=[]){

    let url = window.location.href;
    eventData['screen_name'] = getScreeName(url);
    const orgId = window.localStorage.getItem('organizationId');
    const userId = window.localStorage.getItem('userId');
    const currentTimeStamp = new Date().getTime();

    const isAuth = isAuthenticated();

    // By default, We need to send the following attributes for every authenticated event
    // 1) user_id  2) org_id
    if (isAuth) {
      eventData['user_id'] = userId;
      eventData['org_id'] = orgId;
    }

    // By default, We need to send the following attributes for every event, no matter what
    // 1) platform  2) timestamp
    eventData['platform'] = isMobile ? "mWeb" : "Desktop";
    eventData['timestamp'] = currentTimeStamp;

    if (mode == 'live'){
      if (analyticServices.includes('clevertap')) {
        CleverTap.event(eventName, eventData);
      }
      if (analyticServices.includes('ga')) {
        firebaseAnalytics.logEvent(eventName, eventData);
      }
      if (analyticServices.includes('amp')) {
        if(window.flutter_inappwebview) {
          window.flutter_inappwebview.callHandler("logEvent", eventName, eventData);
        }
        else {
          amplitudeLogEvent(eventName, eventData);
        }
      }
    }else{
      console.log('call for event '+ eventName);
      console.log(eventData);
    }
    
  },

  pageLoad: function(eventCategory){
    const pageName = getScreeName(window.location.href);
    if(!eventCategory) eventCategory = this.getCategory();
    
    eventObj.pageLoad(pageName, eventCategory);
    this.event('Page_Load')
  },

  buttonClick: function(eventName, eventCategory, data){
    const pageName = getScreeName(window.location.href);
    if(!eventCategory) eventCategory = this.getCategory();

    eventObj.buttonClick(eventName, pageName, eventCategory, data);
    this.event(eventName, {...data});
  },

  decisionEvent: function(eventName, eventCategory, data){
    const pageName = getScreeName(window.location.href);
    if(!eventCategory) eventCategory = this.getCategory();

    eventObj.decisionEvent(eventName, pageName, eventCategory, data);
    this.event(eventName, {...data});
  },

  getCategory: function(){
    const url = window.location.href;
    const categoryURLs = [{'key': EventCategory.PRE_PAYMENT, 'urls': ['customer/payScreen?','customer/payScreen/netBanking?','customer/payScreen/upi?','customer/payScreen/neft-rtgs?','customer/payScreen/card?','customer/payScreen/customer-data?']},{'key': EventCategory.POST_PAYMENT, 'urls': ['customer/payScreen/success']}];
    let category;

    for(let record of categoryURLs){
      for(let cUrl of record.urls){
        if(url.includes(cUrl)){
          category = record.key;
        }
      }
    }

    return category;
  }
};


export default analytics;

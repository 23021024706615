import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk';
import reducers from './reducer';
import { composeWithDevTools } from '@redux-devtools/extension';
const history = createBrowserHistory();
const middlewares = [thunk];
const composeEnhancers = compose;



const store = createStore(
    combineReducers({
        ...reducers
    }),
    (process.env.NODE_ENVIRONMENT === "local") ?
    composeWithDevTools(
        applyMiddleware(...middlewares),
    ) :
    composeEnhancers(
        applyMiddleware(...middlewares)
    )
);

export {
    store,
    history
};
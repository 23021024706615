import firebase from "firebase/app";
import "firebase/analytics";

export const firebaseConfig = {
    apiKey: "AIzaSyB_2i0TG8b__4Rywb-2HxXWke3fNAIQ0MY",
    authDomain: "credflow-backend.firebaseapp.com",
    databaseURL: "https://credflow-backend.firebaseio.com",
    projectId: "credflow-backend",
    storageBucket: "credflow-backend.appspot.com",
    messagingSenderId: "922140687523",
    appId: "1:922140687523:web:c2fe39fbb4df62a6b61316",
    measurementId: "G-QG9XRM2QC6"
};

firebase.initializeApp(firebaseConfig);

export const firebaseAnalytics = firebase.analytics();
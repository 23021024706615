
import { GET_CONTACTS_RTO, GET_DEBTOR_SUMMARY_REPORT, GET_SALES_MANAGER_REPORT, GET_PORJECTED_COLLECTION_REPORT, SET_OVERVIEW_OF_SALES_OF_DEBTOR, GET_BUSY_TOTAL_SALE } from '../constants';

const initialState = {
}

export const getDebtorSummaryDetails = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_DEBTOR_SUMMARY_REPORT: 
            return { ...state, debtorSummaryReport: action.payload}
        case GET_BUSY_TOTAL_SALE: 
            return { ...state, busyTotalSale: action.payload}
        case GET_CONTACTS_RTO: 
            return { ...state, contactsRTO: action.payload}
        case GET_SALES_MANAGER_REPORT:
            return { ...state, salesManagerReport: action.payload}
        case GET_PORJECTED_COLLECTION_REPORT: 
            return { ...state, projectedCollectionReport: action.payload}
        case SET_OVERVIEW_OF_SALES_OF_DEBTOR: 
        return { ...state, overviewOfSalesOfDebtor: action.payload}
        default:
            return state;
    }
}
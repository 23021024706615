import { UPDATE_STOCK_CATEGORIES,UPDATE_STOCK_GROUPS,UPDATE_STOCK_ITEMS, UPDATE_STOCK_ITEMS_INVOICES_BY_ID, UPDATE_STOCK_ITEMS_BILLS_BY_ID, UPDATE_STOCK_ITEMS_CUSTOMERS, UPDATE_STOCK_ITEMS_SUPPLIERS } from "../constants";

const initialState = {

}

export const stockCategories  = (state = initialState, action = {}) => {
  switch(action.type) {
    case UPDATE_STOCK_CATEGORIES:
      return { ...state, stockCategories: action.payload}
    default:
      return state;
  }
}

export const stockGroups  = (state = initialState, action = {}) => {
    switch(action.type) {
        case UPDATE_STOCK_GROUPS:
            return{...state, stockGroups: action.payload} 
            default:
                return state;
        }
}
export const stockItems= (state = initialState, action = {}) => {
    switch(action.type) {
    case UPDATE_STOCK_ITEMS:
        return {...state, stockItems: action.payload} 
    default:
      return state;
    }
}

export const stockItemsInvoicesById = (state = initialState, action ={}) => {
  switch(action.type) {
    case UPDATE_STOCK_ITEMS_INVOICES_BY_ID:
        return {...state, stockItemsInvoicesById: action.payload} 
    default:
      return state;
    }
}

export const stockItemsBillsById = (state = initialState, action ={}) => {
  switch(action.type) {
    case UPDATE_STOCK_ITEMS_BILLS_BY_ID:
        return {...state, stockItemsBillsById: action.payload} 
    default:
      return state;
    }
}

export const stockItemsCustomers= (state = initialState, action = {}) => {
  switch(action.type) {
  case UPDATE_STOCK_ITEMS_CUSTOMERS:
      return {...state, stockItemsCustomers: action.payload} 
  default:
    return state;
  }
}

export const stockItemsSuppliers= (state = initialState, action = {}) => {
  switch(action.type) {
  case UPDATE_STOCK_ITEMS_SUPPLIERS:
      return {...state, stockItemsSuppliers: action.payload} 
  default:
    return state;
  }
}

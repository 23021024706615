import { validateOrbitSignup } from '../api/orbit';
import { userAuthority } from '../api/user';
import { checkUserAndgetAllOrganization, handleCatch, removeUserCredentials, setAccessToken, setAuthority, setOrganizationAccess, setOrganizationId, setRefreshToken } from '../helpers/utils';

export const orbitSignUpCheck = (token, clientId) => {
    validateOrbitSignup(token, clientId)
        .then((res) => {
            if (res.status === 200) {
                const { authorization } = res.headers;

                const accessToken = authorization.split(":")[0];
                const refreshToken = authorization.split(":")[1];

                setAccessToken(accessToken);
                setRefreshToken(refreshToken);

                checkUserAndgetAllOrganization()
                    .then((response) => {
                        setOrganizationId(response.data[0].orgId);
                        setOrganizationAccess(response.data[0].access);

                        userAuthority()
                            .then((response) => {
                                setAuthority(Array.isArray(response.data) ? response.data : []);
                                window.location.href = "/paymentRecovery/all";
                            })
                            .catch((err) => {
                                removeUserCredentials({ showMessage: true });
                            });
                    })
                    .catch((err) => {
                        removeUserCredentials({ showMessage: true });
                    });
            }
        })
        .catch((err) => {
            handleCatch(err); a
        })
}
import {
    UPDATE_MONTHWISE_SUMMARY,
   UPDATE_MONTHWISE_OUTSTANDINGS,
  UPDATE_MONTHWISE_OVERALLRTO,
  UPDATE_MONTHWISE_COLLECTION
   } from "../constants";

const initialState = {

}

export const monthwiseSummary=(state = initialState, action = {})=>{

    switch(action.type) {
        
        case UPDATE_MONTHWISE_SUMMARY:
        
        
          return { ...state, ...action.payload}
        default:
          
          return state;
      }
}

export const monthwiseCollection=(state=initialState,action={})=>{
    switch(action.type) {
        case UPDATE_MONTHWISE_COLLECTION:
          return { ...state, ...action.payload}
        default:
          return state;
      }
}

export const monthwiseOutstandings=(state=initialState,action={})=>{
    switch(action.type) {
        case UPDATE_MONTHWISE_OUTSTANDINGS:
          return { ...state, ...action.payload}
        default:
          return state;
      }
}

export const monthlyRTO=(state=initialState,action={})=>{
    switch(action.type) {
        case UPDATE_MONTHWISE_OVERALLRTO:
          return { ...state, ...action.payload}
        default:
          return state;
      }
}


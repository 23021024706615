import React from 'react';
import 'regenerator-runtime/runtime';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { store, history } from './store';
import './index.css';
import AppRouter from './router'
import * as serviceWorker from './serviceWorker';
import {initializeServices} from './startupServices';

initializeServices();

ReactDOM.render(
    <Provider store={store}> 
        <AppRouter history={history} />
    </Provider>,
    document.getElementById('root'));

serviceWorker.register();